import * as React from 'react';
export const PrivacyPage = () => {
  return <div className="container">
    <div>
      <div className="headline">
        <br/>
        <h2 style={{textAlign:"center"}}>Палітыка прыватнасці / Privacy Policy</h2>
      </div>
    </div>
    <div className="row" style={{marginLeft:"10px",fontSize:"1.2em"}}>
      <p>
        Актуальна з 6 лютага 2022
      </p>
      <p>Прыватнасць</p>
      <p>
        Калі say.by (гэта мы), атрымлівае інфармацыю ад вас праз мабільны дадатак "Мова прафесіі", donar.by, mova.pro ці
        say.by, наша Палітыка прыватнасці апісвае, якім чынам мы апрацоўваем гэту інфармацыю.
      </p>
      <p>
        <b>Дэмаграфічныя звесткі, звесткі пра ваш раён.</b> Апцыянальна вы можаце дасылаць нам інфармацыю пра ваш пол,
        узрост, краіну, горад і раён у якім вы пражываеце, бавіце час, або працуеце.
        Гэта дапамагае нам дзяліцца агульнымі звесткамі колькі людзей удзельнічае ў адраджэнні беларускай мовы,
        атрымлівае сертыфікаты Мовы прафесіі, а таксама ахвяруе свой голас.
        Акрамя таго, гэта дапамагае даследчыкам лепей выкарыстоўваць галасавыя звесткі для тэхналогій распазнавання
        маўлення.
      </p>
      <p>
        <b>Звесткі вашага акаўнта.</b> Для ўсіх праектаў say.by дзейнічае аб'яднаны акаўнт донара беларускай мовы.
        Для выкарыстоўвання donar.by вам не абавязкова завадзіць акаўнт, для Мовы прафесіі - абавязкова.
        Калі вы вырашыце стварыць акаўнт, то нам спатрэбіцца ваш імэйл ды імя. Апцыянальна - дэмаграфічныя звесткі і
        раён. Таксама можна зрабіць свой профіль публічным (у профілі - бачнасць у рэйтынгу - "бачны"). У гэтым выпадку,
        вы будзеце ўдзельнічаць у рэйтынгу, а таксама ваш сертыфікат донара беларускай мовы стане публічным і ўсе
        змогуць пабачыць ваш раён, інстаграм, лінкед-ін акаўнт, пол. Ваш узрост застаецца таямніцай незалежна ад
        наладкаў публічнасці.
        Калі вы вырашыце зрабіць свой профіль "схаваным", то мы ананімізіруем вашую прысутнасць, а дадзеныя пра ваш раён
        і факт праходжання курсаў Мовы прафесіі будзе ўдзельнічаць толькі ў абагуленых статыстычных звестках па сайту і
        мабільнаму дадатку. Мы нідзе не публікуем ваш email. Вы можаце даслаць нам імэйл на say@say.by, калі пажадаеце
        выдаліць свае дадзеныя.
      </p>
      <p>
        <b>Галасавыя звесткі.</b> На donar.by мы збіраем вашыя галасавыя звесткі, якія перадаюцца ў Common Voice а
        таксама захоўваюцца на нашых серверах.
        У датасеце Common Voice яны ананімізіраваны, і згрупаваны з улікам дэмаграфічных звестак. Агучванні для Мовы
        прафесіі - выкарыстоўваюцца ў заданнях праекта Мовы прафесіі - у мабільным дадатку, а таксама
        ў інтэграцыях ва ўнутраныя адукацыйныя сістэмы кампаній, якія падтрымліваюць беларускую мову. Галасы, якія
        запісаны на donar.by стаюць даступнымі для агульнага карыстання пад ліцэнзіяй Creative Commons Zero License
        (CC0).
        Калі ў вас "бачны" профіль, то адзін ці некалькі вашых запісаў могуць быць даступны для праслухоўванні ў вашым
        профілі беларускай мовы. Запісы захоўваюцца на беларускіх серверах.
      </p>
      <p>
        <b>Тэкст.</b> Адказы на пытанні, у тым ліку і тэкставыя захоўваюцца на нашых серверах, але не публікуюцца ў
        адкрытым доступе. У тым ліку яны недаступны прадстаўнікам кампаній, у якіх вы, магчыма, працуеце.
      </p>
      <p>
        <b>Вынікі заданняў.</b> Калі ваша прадпрыемства падключана да Мовы прафесіі, то прадстаўнікам кампаніі, адказным
        за тэсціраванне могуць быць даступны вынікі прахаджэння задання (прайшоў / не прайшоў і % правільных адказаў).
        Калі вы выкарыстоўваеце Мову прафесіі для сябе без прывязкі да прадпрыемства, вынікі індывідуальных заданняў не
        ананімны. Калі ў вас "бачны" профіль, то ў вашым сертыфікаце будзе апублікованы толькі факт паспяховага
        праходжання сертыфікацыйнага курса.
      </p>
      <p>
        <b>Дадзеныя наведвальнікаў.</b> Мы выкарыстоўваем Google Analytics для збору інфармацыі пра тое, якія старонкі
        сайта наведваюцца. У тым ліку з выкарыстоўваннем cookies для атрымлівання ананімізаванай і абагуленай інфармацыі
        без прывязкі да дакладнага карыстальніка сістэмы. Google Analytics можа сачыць за тым, якія кнопкі націскаюцца і
        колькі запісаў зроблена, працягласць сессіі.
      </p>
      <p>
        <b>Тэхнічныяя звесткі.</b> Мы выкарыстоўваем Google Analytics для разумення, хто заходзіць на сайт і якім чынам
        узаемадзейнічае з мабільным дадаткам Мовы прафесіі. Таксама выкарыстоўваецца facebook pixel для ўзаемадзеяння з
        аўдыторыяй праз сацыяльныя сеткі.
      </p>
      <p>
        <b>Facebook application / уваход праз facebook</b>. Мы выкарыстоўваем Facebook application, калі вы жадаеце
        ажыццявіць "уваход праз Facebook".
        Для гэтых мэтаў мы інтэгравалі праграму Auth0, якая дапамагае з праверкай сапраўднасці ўліковага запісу, а
        таксама з рэгістрацыяй у сістэме. <a href=" https://auth0.com/docs/secure/data-privacy-and-compliance">Палітыку
        прыватнасці сервіса Auth0 можна прачытаць па спасылцы</a>.
        Пра захоўванне галасавых звестак (аўдыяфайлаў з запісамі галасоў карыстальнікаў) можна прачытаць вышэй у секцыі
        "галасавыя звесткі".
        Пра іншую інфармацыю, якая захоўваецца падчас рэгістрацыі карыстальніка ў сістэме можна прачытаць вышэй у секцыі
        "звесткі вашага акаўнта".
      </p>
      <p>
        <b>Выдаленне вашага акаўнта, звестак пра вас</b>. Вы можаце даслаць нам імэйл на say@say.by, калі пажадаеце
        выдаліць свае дадзеныя.
        Мы выдалім ваш акаўнт, дэмаграфічныя звесткі, звесткі пра ваш раён, звесткі вашага акаўнта, інфармацыю з сервіса
        аўтэнтыфікацыі Auth0 і, пры неабходнасці, вашыя галасавыя звесткі з нашых сервераў, якія былі раней перададзены
        пад ліцэнзіяй Creative Commons Zero License (CC0).
      </p>
      <br/>
      <br/>
    </div>
  </div>
}
