import * as React from 'react';

export const TermsPage = () => {
  return  <div className="container">
    <div>
      <div className="headline">
        <br/>
        <h2 style={{textAlign:"center"}}>Правілы і ўмовы / Terms and Conditions</h2>
      </div>
    </div>
    <div className="row" style={{marginLeft:"10px",fontSize:"1.2em"}}>
      <p>
        Актуальна з 20 лістапада 2023
      </p>
      <p><b>Што мы тут робім</b></p>
      <p>
        Дапамагаем тэхналагічнай будучыні нашай мовы (каб кампутары разумелі жывую размову)! Пераходзім на беларускую, вучымся ёй, трэніруем маўленне, слухаем галасы беларусаў, калекцыянуем асаблівасці гаворкі раёнаў Беларусі.

        З тэхнічнага пункту гледжання Donar.by — гэта разгалінаванне Common Voice з мэтай большага паглыблення ў патрэбы беларускай супольнасці. Адпаведныя агучванні аўтаматычна трапляюць і на Common Voice.
      </p>
      <p>
        Зараз вы можаце ахвяраваць свой голас, каб дапамагчы нам пабудаваць адкрытую базу галасоў, якую кожны зможа выкарыстоўваць для распрацоўкі інавацыйных праграм для прылад і сайтаў. Прачытайце сказ, каб дапамагчы машынам вывучыць мову сапраўдных людзей. Праверце працу іншых удзельнікаў, каб палепшыць якасць. Гэта так проста!
      </p>
      <p><b>Прыдатнасць</b></p>
      Donar.by адкрыты для ўсіх! Майце на ўвазе, калі вам 19 гадоў і менш, вы павінны атрымаць згоду бацькоў або апекуна, і яны павінны кантраляваць ваш удзел у Donar.by.
       <p><b>Вашы ўнёскі</b></p>
      <p>
        Мы робім базу дадзеных Donar.by даступнай пад правам грамадскага здабытку Creative Commons CC0. Гэта азначае, што гэта публічна, і мы адмовіліся ад усіх аўтарскіх правоў, наколькі гэта магчыма ў адпаведнасці з законам. Калі вы ўдзельнічаеце ў Donar.by, мы патрабуем, каб вы зрабілі тое ж самае. Вы павінны пагадзіцца з тым, што donar.by можа прапанаваць усе вашыя ўклады (уключаючы тэксты, запісы, праверкі і водгукі) грамадскасці пад прызначэннем грамадскага здабытку CC0.

        Каб прыняць удзел у Donar.by, таксама патрабуе, каб вы зрабілі дзве гарантыі:

        <p>Па-першае, што вашы ўнёскі - гэта цалкам ваша ўласнае стварэнне.</p>

        <p>Па-другое, каб вашы ўклады не парушалі правоў трэціх асоб.</p>

        Калі вы не можаце зрабіць гэтыя гарантыі, вы не можаце ўдзельнічаць у Donar.by</p>
      <p><b>Common Voice</b></p>
      <p>Пагадняючыся на гэтыя правілы і ўмовы вы таксама пагадняецеся з правіламі і ўмовамі ад <a href={"https://commonvoice.mozilla.org/be/terms"} target={"_blank"}>Mozilla Common Voice</a>, бо вашыя сабраныя агучвання трапляюць і туды.</p>
      <p><b>Палітыка прыватнасці</b></p>
      <p>Пабачыць нашу палітыку прыватнасці можна тут: <a href={"https://say.by/privacy"} target={"_blank"}>Палітыка Праватнасці.</a></p>
      <br/>
      <br/>
    </div>
  </div>;
}
