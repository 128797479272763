import * as React from 'react';
import { useRef, useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import API from '../../../services/api';
import DonorAPI from '../../services/donor-api'
import {DonorPublicProfile} from '../../services/donor-api';
import StateTree from '../../../stores/tree';
import {REGIONS, SUBREGIONS} from '../../stores/region';



import './public-profile.css';

interface PropsFromState {
  api: API;
}
type State = {
  count: number;
}

class PublicProfile extends React.Component<
    {
      donorId: number
    } & PropsFromState,
    State
    > {
  render () {
    return (
      <RunCanvas donorId={this.props.donorId} dAPI = {this.props.api.donorAPI} />
    );
  }
}
const mapStateToProps = ({ api }: StateTree) => ({
  api
});

let canvas: HTMLCanvasElement;
let ctx: CanvasRenderingContext2D;
let profileData:DonorPublicProfile;
let audioElement:HTMLAudioElement;
let audioSourceElement:HTMLSourceElement;
let containerDiv:HTMLDivElement;
let downloadLink:HTMLDivElement;
let notADonorDiv:HTMLDivElement;
let notAllowedToViewDiv:HTMLDivElement;
let profileIsHiddenButYouCanLookDiv:HTMLDivElement;
let loadingDiv:HTMLDivElement;

let allImages:HTMLImageElement[] = [];


let img = allImages[allImages.length] = new Image();
let imgAvatara = allImages[allImages.length] = new Image();
// imgAvatara.crossOrigin = '*';
const playButton = allImages[allImages.length] = new Image();
const pauseButton = allImages[allImages.length] = new Image();
const micIcon = allImages[allImages.length] = new Image();
const instagramIcon = allImages[allImages.length] = new Image();
const markerImage = allImages[allImages.length] = new Image();
const volunteeringIcon = allImages[allImages.length] = new Image();
const proLabelUpgrade = allImages[allImages.length] = new Image();
// canvas.onclick =  handleClick;
// canvas.onmousemove =  handleMouseOver;



let lastAchievementIYCoordinate = 400-1;
let lastAchievementIXCoordinate = 637;
let achievementGapY = 110;

let activityXBaseCoordinate = lastAchievementIXCoordinate;
let activityYBaseCoordinate = 624;
let activityGapY = 30;
let proYBaseCoordinate = 624;
let proXBaseCoordinate = lastAchievementIXCoordinate;
let proGapY = 30;

const micButtons = [
  {ix:lastAchievementIXCoordinate-43, iy:lastAchievementIYCoordinate-21, iw:22*1.2, ih:22*1.2},
  {ix:lastAchievementIXCoordinate-43, iy:lastAchievementIYCoordinate+achievementGapY-21, iw:22*1.2, ih:22*1.2}
];

const activityButtonCoordinate = {ix:activityXBaseCoordinate - 57, iy:activityYBaseCoordinate - 23, iw:20*2.2, ih:15*2.2};

const playButtonCoordinate = {ix:350, iy:475, iw:35, ih:35};
const instagramButtonCoordinate = {ix:353, iy:545, iw:30, ih:30};

const regionYStartPoint = 260-1;
const regionIconCoordinate = {ix:590, iy:regionYStartPoint, iw:13*2.5, ih:20*2.5};


let isPlaybutton = true;
let isMobile = false;


playButton.src = "/img/certificate/play-button.svg";
pauseButton.src = "/img/certificate/pause-button.svg";
micIcon.src = "/img/certificate/mic-icon.svg";
instagramIcon.src = "/img/certificate/instagram-icon.svg";
markerImage.src = "/img/certificate/marker.svg";
volunteeringIcon.src = "/img/certificate/volunteering-icon.svg";
proLabelUpgrade.src = "/img/certificate/pro-label.svg";

let imagesLoaded:number = 0;

let oldCanvas:HTMLCanvasElement;
let oldCTX:CanvasRenderingContext2D;

function timeout(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitForLoadAllImages() {
  let totalMSPassed = 0;
  //120 seconds
  let maximumLoadTimeForImages = 15*1000;
  const iterationTimeMS = 50;

  let notEverythingLoaded = false;
  while (totalMSPassed < maximumLoadTimeForImages) {
    notEverythingLoaded = false;
    for(let x=0; x < allImages.length; x++) {
      if(allImages[x].complete && allImages[x].naturalHeight !== 0 || !allImages[x].src) {
        continue;
      } else {
        // console.log("not loaded - " + allImages[x].src);
        notEverythingLoaded = true;
      }
    }
    if(notEverythingLoaded) {
      await timeout(iterationTimeMS);
      totalMSPassed += iterationTimeMS;
    } else {
      console.log("loaded images after: " + totalMSPassed);
      return true;
    }
  }
}

async function drawCertificate(biggerResolution:boolean = false)
{
  //try {
    // canvas.width = canvas.height = document.body.clientWidth < 700 ? document.body.clientWidth: document.body.clientWidth - 600;
    //document.body.clientWidth;
    if (biggerResolution) {
      //for download only!
      oldCanvas = canvas;
      oldCTX = ctx;
      canvas = document.createElement('canvas');
      ctx = canvas.getContext('2d');
      isMobile = false;
      canvas.width = canvas.height = 2000;
    } else if (containerDiv.offsetWidth < 768) {
      containerDiv.style.width = "100%";
      containerDiv.style.overflow = "hidden";

      //mobile look
      isMobile = true;
      const gapFromLeft = 140;
      const gapFromRight = 110;
      let scale = window.devicePixelRatio;
      console.log('scale - ' + scale);
      let originalDims = containerDiv.offsetWidth + toRealCoord(gapFromLeft + gapFromRight, containerDiv.offsetWidth);
      canvas.style.width = canvas.style.height = Math.floor(originalDims) + "px";
      canvas.width = canvas.height = Math.floor(originalDims * scale);
      canvas.style.marginLeft = "-" + toRealCoord(gapFromLeft, containerDiv.offsetWidth) + "px";
      // canvas.style.zoom="" + (1/scale);
      // ctx.scale(scale, scale);
      // console.log(originalDims);

    } else {
      //desktop full look
      isMobile = false;
      canvas.width = canvas.height = Math.ceil(containerDiv.offsetWidth);
    }

    if (!biggerResolution && oldCanvas) {
      canvas = oldCanvas;
      ctx = oldCTX;
      oldCanvas = null;
      oldCTX = null;
    }
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    var avatarDesiredWidth = toRealCoord(179);
    var avatarX = toRealCoord(286);
    var avatarY = toRealCoord(147);
    let avatar = null;
    //pushing through proxy - so there is no violation and we can download canvas
    if (profileData.avatarUrl) {
      //to be able to download canvas
      imgAvatara.src = profileData.avatarUrl.replace('https://io.activecloud.com', '/io.activecloud');
    } else {
      imgAvatara.src = '/img/certificate/balaka-certificate.svg';
    }
    if (profileData.gender === 'female') {
      img.src = "/img/certificate/certificate-template-f.svg";
    } else {
      img.src = "/img/certificate/certificate-template-m.svg";
    }
    if (!imagesLoaded) {
      await waitForLoadAllImages();
      imagesLoaded++;
    }

    if (profileData.avatarUrl) {
      avatar = createAvatar(imgAvatara);//document.getElementById('avatar');
    } else {
      avatar = imgAvatara;
    }
    var proportion = avatar.width / avatar.height;
    var newHeight = avatarDesiredWidth / proportion;

    ctx.drawImage(avatar, avatarX, avatarY, avatarDesiredWidth, newHeight);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

     //drawNet();
    //
    drawName(profileData.username);
    if (profileData.isHonored) {
      if (profileData.gender === 'female') {
        drawCertiticateTitle("Ганаровая донарка беларускай мовы");
      } else {
        drawCertiticateTitle("Ганаровы донар беларускай мовы");
      }
    } else {
      if (profileData.gender === 'female') {
        drawCertiticateTitle("Донарка беларускай мовы");
      } else {
        drawCertiticateTitle("Донар беларускай мовы");
      }
    }
    
    if(profileData.movaPro.length) {
        upgradeToPro();
    }

    if (!audioSourceElement.src || audioSourceElement.src.includes('blank.mp3')) {
      audioSourceElement.src = profileData.voice;
      audioElement.load();
    }
    drawCertificateNumber("№" + convertIdToDonorNumber(profileData.donorId));

    drawListenToVoice();

    if (profileData.instagram) {
      drawInstagramLink(profileData.instagram.replace('https://instagram.com/', ''));
    }

    let currentAchievementNumber = 0;

    //some default styling
    ctx.fillStyle = "#4f4f4f";
    ctx.font = toRealFontSize(27) + "px Rubik";
    ctx.textAlign = "left";

    if (profileData.commonVoiceRecords || profileData.commonVoiceVotes) {
      drawVoiceContribution(profileData.commonVoiceRecords, profileData.commonVoiceVotes, "Common Voice", currentAchievementNumber);
      currentAchievementNumber++;
    }
    if (profileData.movaProRecords || profileData.movaProVotes) {
      drawVoiceContribution(profileData.movaProRecords, profileData.movaProVotes, "Donar.by і Mova.pro", currentAchievementNumber);
    }
    if ((profileData.isHonored || profileData.volunteering) && profileData.about) {
        aboutText(profileData.about, isMobile ? 140 : 95, 660, isMobile ? 395 : 440, 16, 'Rubik');
    }
    if (profileData.mainRegion) {
        drawRegion(REGIONS[profileData.mainRegion.region],
        profileData.mainRegion.subregion ? SUBREGIONS[profileData.mainRegion.region][profileData.mainRegion.subregion] : '',
        profileData.donorsAmountNearby);
    }
    let amountOfVolunteerings = 0;
    if (profileData.volunteering) {
      for (let k in profileData.volunteering) {
        amountOfVolunteerings++;
        drawVolunteering(profileData.volunteering[k], parseInt(k) - 1);
      }
    }
    if (profileData.movaPro.length) {
        amountOfVolunteerings++;
        proYBaseCoordinate = activityYBaseCoordinate + amountOfVolunteerings*activityGapY;

        drawMovaPro("Мова прафесіі", 0);

        for(let x=0; x < profileData.movaPro.length; x++) {
            drawMovaPro(profileData.movaPro[x], x+1);
        }
    }
    // drawVolunteering("Аўтар курсу Мова прафесіі", 1);

    showCertificateFinally();
 // } catch(err) {
 //   alert(err);
//    alert('Адбылася памылка, паспрабуйце іншы браўзер. Напрыклад, Google Chrome.');
  //}
  //split to 1000 on 1000
}
function showCertificateFinally()
{
  loadingDiv.style.display = "none";
  downloadLink.style.display="block";
  canvas.style.display="block";
}
function convertIdToDonorNumber(donorId: number)
{
  return donorId.toString().padStart(7, "0");
}
function createAvatar(sourceImage:HTMLImageElement)
{
  let image:HTMLImageElement | HTMLCanvasElement;
  if(sourceImage.width > sourceImage.height) {
    //we need to update images, so it is at least a square
    let tmpConvertCanvas = document.createElement('canvas'),
      tmpConvertCtx = tmpConvertCanvas.getContext('2d');
    tmpConvertCanvas.width=sourceImage.width;
    tmpConvertCanvas.height=sourceImage.width;
    let multiplier = sourceImage.width/sourceImage.height;
    tmpConvertCtx.drawImage(sourceImage,(sourceImage.width - sourceImage.height)/2,0,sourceImage.width,sourceImage.height,0,0,sourceImage.width*multiplier,sourceImage.height*multiplier);
    image = tmpConvertCanvas;
  } else {
    image = sourceImage;
  }

  var tmpCanvas = document.createElement('canvas'),
    tmpCtx = tmpCanvas.getContext('2d');

  var desiredWidth = 600;
  var proportion = image.width/image.height;
  var desiredHeight = desiredWidth/proportion;

  tmpCanvas.width = desiredWidth*4;
  tmpCanvas.height = desiredHeight*4;

  // draw the cached images to temporary canvas and return the context
  tmpCtx.save();
  tmpCtx.beginPath();
  // tmpCtx.arc(2*desiredWidth, 2*desiredWidth, 2*desiredWidth, 0, Math.PI*2, true);
  tmpCtx.arc(2*desiredWidth, 2*desiredWidth, 2*desiredWidth, 0, Math.PI*2, true);
  tmpCtx.closePath();
  tmpCtx.clip();

  tmpCtx.drawImage(image, 0, 0, 4*desiredWidth, 4*desiredWidth/proportion);
  // tmpCtx.drawImage(imgAvatara, 0, 0, 4*desiredWidth, 4*desiredHeight);

  tmpCtx.beginPath();
  tmpCtx.arc(0, 0, 50, 0, Math.PI*2, true);
  tmpCtx.clip();
  tmpCtx.closePath();
  tmpCtx.restore();
  return tmpCanvas;
}

function toRealCoord(internalCoordinate:number, canvasWidth:null | number = null) {
  // console.log("canvas width:" +canvas.width)
  // console.log( (canvas.width/1000) * internalCoordinate);
  if(!canvasWidth) {
    canvasWidth = canvas.width;
  }
  return Math.round((canvasWidth/1000) * internalCoordinate);
}
function toInternalCoord(realCoordinate:number) {
  return Math.round(realCoordinate * 1000  / canvas.width);
}
function toRealFontSize(fontSize:number) {
  //we are taking into account that the original font is for 1080 canvas width, and we scale proportionally
  let realFontSize = Math.round((fontSize*canvas.width)/1080);

  return realFontSize;
}

function drawName(name:string)
{
  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik-bold";
  ctx.textAlign = "center";
  ctx.fillText(name, toRealCoord(370), toRealCoord(400));
}
function drawCertiticateTitle(title:string)
{
  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "center";
  ctx.fillText(title, toRealCoord(370), toRealCoord(430));
}
function drawCertificateNumber(certificateNumber:string)
{
  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "center";
  ctx.fillText(certificateNumber, toRealCoord(370), toRealCoord(460));
}
function drawListenToVoice()
{
    if(
        profileData.commonVoiceRecords || profileData.commonVoiceRecords || profileData.voice
    ) {
    ctx.fillStyle = "#9C9C9C";
    ctx.font = toRealFontSize(27) + "px Rubik";
    ctx.textAlign = "center";
    ctx.fillText("паслухаць голас", toRealCoord(367), toRealCoord(530));
    let buttonToInsert = playButton;
    if(!isPlaybutton) {
      buttonToInsert = pauseButton;
    }
    ctx.drawImage(buttonToInsert, toRealCoord(playButtonCoordinate.ix), toRealCoord(playButtonCoordinate.iy), toRealCoord(playButtonCoordinate.iw), toRealCoord(playButtonCoordinate.ih));
 }
}

function listenToDonor() {
  audioElement.play();
  isPlaybutton = false;
  drawCertificate();
  let listenCheckInterval:ReturnType<typeof setInterval>;
  listenCheckInterval = setInterval(function(){
    if(audioElement.paused) {
      clearInterval(listenCheckInterval);
      pauseDonor();
    }
  },1000);

}
function pauseDonor()
{
  audioElement.pause();
  isPlaybutton = true;
  drawCertificate();
}
function leaveOnlyInstagramAccountName(accountName:string) {
  return accountName.replace('https://www.instagram.com/','').replace('https://instagram.com/','').replace(/\//g,'').replace('@','').replace('?hl=ru','').replace('?hl=en','');
}
function drawInstagramLink(accountName:string) {
  ctx.fillStyle = "#9C9C9C";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "center";
  ctx.fillText(
    leaveOnlyInstagramAccountName(accountName),
   toRealCoord(367), toRealCoord(599));
  ctx.drawImage(instagramIcon, toRealCoord(instagramButtonCoordinate.ix), toRealCoord(instagramButtonCoordinate.iy), toRealCoord(instagramButtonCoordinate.iw), toRealCoord(instagramButtonCoordinate.ih));
}
function drawVoiceContribution(recordAmount:number, listenAmount:number, purpose:string, achievementNumber:number)
{
  var iCoordX = lastAchievementIXCoordinate;
  var iCoordY = lastAchievementIYCoordinate + achievementNumber * achievementGapY;

  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "left";
  ctx.fillText(new Intl.NumberFormat('be-BY').format(recordAmount) + "/" + new Intl.NumberFormat('be-BY').format(listenAmount),
    toRealCoord(iCoordX), toRealCoord(iCoordY));

  ctx.fillStyle = "#9C9C9C";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "left";
  ctx.fillText("Агучана і праверана", toRealCoord(iCoordX), toRealCoord(iCoordY + 36));

  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "left";
  ctx.fillText(purpose, toRealCoord(iCoordX), toRealCoord(iCoordY + 65));

  ctx.drawImage(micIcon,  toRealCoord(micButtons[achievementNumber].ix),
    toRealCoord(micButtons[achievementNumber].iy),
    toRealCoord(micButtons[achievementNumber].iw),
    toRealCoord(micButtons[achievementNumber].ih)
  );

}

function drawVolunteering(activity:string, activityNumber:number)
{
  let iCoordX = activityXBaseCoordinate;
  let iCoordY = activityYBaseCoordinate;
  iCoordY = activityYBaseCoordinate + activityNumber * activityGapY;

  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "left";
  ctx.fillText(activity, toRealCoord(iCoordX), toRealCoord(iCoordY));

  if(!activityNumber) {
    //we draw only first time
    ctx.drawImage(volunteeringIcon,  toRealCoord(activityButtonCoordinate.ix),
      toRealCoord(activityButtonCoordinate.iy),
      toRealCoord(activityButtonCoordinate.iw),
      toRealCoord(activityButtonCoordinate.ih));
  }
}
function upgradeToPro() {
//proLabelUpgrade();
// 490 / 220
    ctx.drawImage(proLabelUpgrade,  toRealCoord(466),
      toRealCoord(180),
      toRealCoord(17*3),
      toRealCoord(13*3));
}
function drawMovaPro(activity:string, activityNumber:number)
{
  let iCoordX = proXBaseCoordinate;
  let iCoordY = proYBaseCoordinate;
  iCoordY = proYBaseCoordinate + activityNumber * proGapY;

  ctx.fillStyle = "#4f4f4f";
  if(!activityNumber) {
     ctx.font = toRealFontSize(27) + "px Rubik-bold";
  } else {
     ctx.font = toRealFontSize(27) + "px Rubik";
  }
  ctx.textAlign = "left";
  ctx.fillText(activity, toRealCoord(iCoordX), toRealCoord(iCoordY));

  if(activityNumber) {
    //we draw only first time
    ctx.drawImage(proLabelUpgrade,  toRealCoord(activityButtonCoordinate.ix-2),
      toRealCoord(iCoordY-22),
      toRealCoord(17*3),
      toRealCoord(13*3));
  }
}

function aboutText(text:string, ix:number, iy:number, imaxWidth:number, iFontSize:number, fontFace:string) {

  let x = toRealCoord(ix);
  let y = toRealCoord(iy);
  let maxWidth = toRealCoord(imaxWidth);
  let fontSize = toRealFontSize(iFontSize);

  //we will fill it twice, first time to get actual height
  let lastRealY = fillAboutText(text, ix, iy, imaxWidth, iFontSize, fontFace);

  ctx.fillStyle = "#ffffff";
  let paddingX = 30;
  let paddingY = 43;

  ctx.strokeStyle = "#e74c3c";
  ctx.shadowOffsetX = toRealCoord(1);
  ctx.shadowOffsetY = toRealCoord(2);
  ctx.shadowColor = "#000000";
  ctx.shadowBlur = 5;

  ctx.strokeRect(x - toRealCoord(paddingX), y - toRealCoord(paddingY), maxWidth + toRealCoord(paddingX*2), lastRealY - y + toRealCoord(paddingY*1.8));

  ctx.fillStyle = "white";
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.shadowColor = "#000000";
  ctx.shadowBlur = 0;
  ctx.fillRect(x - toRealCoord(paddingX), y - toRealCoord(paddingY), maxWidth + toRealCoord(paddingX*2), lastRealY - y + toRealCoord(paddingY*1.8));

  ctx.fillStyle = "#4f4f4f";


  fillAboutText(text, ix, iy, imaxWidth, iFontSize, fontFace);

}
function fillAboutText(text:string, ix:number, iy:number, imaxWidth:number, iFontSize:number, fontFace:string)
{
  let x = toRealCoord(ix);
  let y = toRealCoord(iy);
  let maxWidth = toRealCoord(imaxWidth);
  let fontSize = toRealFontSize(iFontSize);

  ctx.font = fontSize + " " + fontFace;

  var words = text.split(' ');
  var line = '';
  var lineHeight = toRealCoord(iFontSize + 8);

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + ' ';
    var metrics = ctx.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth) {
      ctx.fillText(line, x, y);
      line = words[n] + ' ';
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  ctx.fillText(line, x, y);

  return y;
}
function drawRegion(region:string, subregion:string, amountOfDonorsInRegion: number) {

  ctx.drawImage(markerImage,  toRealCoord(regionIconCoordinate.ix),
    toRealCoord(regionIconCoordinate.iy),
    toRealCoord(regionIconCoordinate.iw),
    toRealCoord(regionIconCoordinate.ih)
  );
  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  let dims = ctx.measureText(""+amountOfDonorsInRegion);
  ctx.fillText(amountOfDonorsInRegion + "",    toRealCoord(lastAchievementIXCoordinate), toRealCoord(regionYStartPoint+ 23));
  ctx.fillStyle = "#9C9C9C";

  let donorAmountText = amountOfDonorsInRegion === 1 ? "донар" : (amountOfDonorsInRegion < 5 ? "донары" : "донараў");
  ctx.fillText("  " + donorAmountText + " на раёне",    toRealCoord(lastAchievementIXCoordinate) + dims.width, toRealCoord(regionYStartPoint+ 23));

  ctx.fillStyle = "#4f4f4f";
  ctx.font = toRealFontSize(27) + "px Rubik";
  ctx.textAlign = "left";
  ctx.fillText(region,    toRealCoord(lastAchievementIXCoordinate), toRealCoord(regionYStartPoint + 56));
  if(subregion) {
    ctx.fillText(subregion, toRealCoord(lastAchievementIXCoordinate), toRealCoord(regionYStartPoint + 56+31));
  }


}

function handleClick(e:{clientX:number,clientY:number})
{
  var x = e.clientX;
  var y = e.clientY;

  if(isElementOver(x,y,playButtonCoordinate)) {
    if(audioElement.paused || !audioElement.currentTime) {
      listenToDonor();
    } else {
      pauseDonor();
    }
  }
  if(isElementOver(x,y,micButtons[0]) || isElementOver(x,y,micButtons[1])){
    document.location.href = '/be/speak';
  }
  if(profileData.instagram && isElementOver(x,y,instagramButtonCoordinate)) {
    document.location.href = 'https://instagram.com/' + leaveOnlyInstagramAccountName(profileData.instagram) + '/';
  }
  if(profileData.volunteering && isElementOver(x,y,activityButtonCoordinate)) {
    document.location.href = 'https://forms.gle/kBdEHrGp7pgKU4aP6';
  }
  // e.preventDefault();
}
function handleMouseOver(e:{clientX:number,clientY:number}) {
  let elementsToWatch = [
    playButtonCoordinate,
    micButtons[0],
    micButtons[1],
    instagramButtonCoordinate,
    activityButtonCoordinate
  ];
  let x = e.clientX;
  let y = e.clientY;

  let match = false;
  for(let i=0; i<elementsToWatch.length; i++) {
    if(isElementOver(x,y,elementsToWatch[i])) {
      match = true;
      break;
    }
  }
  if(match) {
    canvas.style.cursor = "pointer";
  } else {
    canvas.style.cursor = "default";
  }
}
function isElementOver(xClick:number, yClick:number, elementCoordinates:{ix:number,iy:number,iw:number,ih:number})
{
  let ixClick = toInternalCoord(xClick) - toInternalCoord(canvas.offsetLeft) + toInternalCoord(window.pageXOffset);
  let iyClick = toInternalCoord(yClick) - toInternalCoord(canvas.offsetTop) + toInternalCoord(window.pageYOffset);

  if(isMobile) {
    ixClick = ixClick * window.devicePixelRatio;
    iyClick = iyClick * window.devicePixelRatio;
  }

  if(ixClick>elementCoordinates.ix && ixClick<(elementCoordinates.ix + elementCoordinates.iw) &&
    (
      // isMobile ||
    iyClick>elementCoordinates.iy && iyClick<(elementCoordinates.iy + elementCoordinates.ih)
    )
  ) {
    return true;
  } else {
    return false;
  }

}
function drawNet()
{
  // return false;
  // set line stroke and line width
  ctx.strokeStyle = 'red';
  ctx.lineWidth = 1;
  let netFontsize = 11;
  for(var x=1; x < 50; x++) {
    // draw a red line
    var currentInternalY:number = 1000 - (x*20);
    ctx.beginPath();
    ctx.moveTo(0, toRealCoord(currentInternalY) );
    ctx.lineTo(toRealCoord(1000), toRealCoord(currentInternalY));
    ctx.stroke();
    ctx.font = toRealFontSize(netFontsize) + "px Arial";
    ctx.textAlign = "right";
    ctx.fillText(""+currentInternalY, toRealCoord(20), toRealCoord(currentInternalY));
  }

  for(var x=1; x < 50; x++) {
    // draw a red line
    var currentInternalX = 1000 - (x*20);
    ctx.beginPath();
    ctx.moveTo(toRealCoord(currentInternalX),  0);
    ctx.lineTo(toRealCoord(currentInternalX), toRealCoord(1000));
    ctx.stroke();
    ctx.font = toRealFontSize(netFontsize) + "px Arial";
    ctx.textAlign = "right";
    ctx.fillText(""+currentInternalX, toRealCoord(currentInternalX), toRealCoord(10));
  }
}

function downloadCertificate()
{
  let link = document.createElement('a');
  link.download = `donar-${convertIdToDonorNumber(profileData.donorId)}.png`;
  drawCertificate(true);
  link.href = canvas.toDataURL();
  drawCertificate();
  link.click();
}

async function startCanvasProcess(donorId:number, dAPI: DonorAPI)
{
  if(!profileData) {
    profileData = await dAPI.fetchPublicProfile(donorId);
  }
  if(isAlienProfileHidden()) {
    notAllowedToViewDiv.style.display="block";
    downloadLink.style.display="none";
    loadingDiv.style.display="none";
  } else if(!isActuallyADonor()) {
    drawNotADonorYet();
  } else {
    if(isYourProfileHidden()) {
      profileIsHiddenButYouCanLookDiv.style.display = "block";
    }
    drawCertificate();
  }
}
function isAlienProfileHidden() {
  return !profileData.donorId && !profileData.visible;
}
function isYourProfileHidden() {
  return profileData.donorId && !profileData.visible;
}
function isActuallyADonor():boolean {
  if (
    (profileData.commonVoiceVotes + profileData.movaProVotes) >= 20 ||
    (profileData.commonVoiceRecords + profileData.movaProRecords) >= 10 ||
    profileData.isHonored ||
    profileData.movaPro.length ||
    profileData.volunteering ||
    profileData.movaPro && profileData.movaPro.length
  ) {
    return true;
  } else {
    return false;
  }
}
function drawNotADonorYet() {
  notADonorDiv.style.display="block";
  loadingDiv.style.display="none";
}


function RunCanvas({ donorId, dAPI}: {donorId: number; dAPI: DonorAPI} ) {
  const canvasRef = useRef(null);
  const audioRef = useRef(null);
  const audioSourceRef = useRef(null);
  const containerDivRef = useRef(null);
  const downloadLinkRef = useRef(null);
  const notADonorDivRef = useRef(null);
  const notAllowedToViewDivRef = useRef(null);
  const profileIsHiddenButYouCanLookDivRef = useRef(null);
  const loadingDivRef = useRef(null);

  useEffect(() => {
    canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    audioElement = audioRef.current;
    audioSourceElement = audioSourceRef.current;
    containerDiv = containerDivRef.current;
    canvas.onmousedown = handleClick;
    canvas.onmousemove = handleMouseOver;
    downloadLink = downloadLinkRef.current;
    downloadLink.onclick = downloadCertificate;
    notADonorDiv = notADonorDivRef.current;
    notAllowedToViewDiv = notAllowedToViewDivRef.current;
    profileIsHiddenButYouCanLookDiv = profileIsHiddenButYouCanLookDivRef.current;
    loadingDiv = loadingDivRef.current;
    startCanvasProcess(donorId, dAPI);

  });
  return (
    <div ref={containerDivRef} className="container-div-certificate" style={{padding: "0", width: "100%", textAlign: "center", overflowX: "hidden"}}>
      <div style={{display: "none"}} ref={profileIsHiddenButYouCanLookDivRef} className="not-allowed-to-view-hidden">
        <p>Ваш сертыфікат бачны толькі вам.</p>
        <p><a href="/be/profile/info">Змяніць наладкі бачнасці можна тут.</a></p>
      </div>

      <canvas ref={canvasRef} style={{display:"none"}} id="donor-certificate" width="100" height="100"></canvas>

      <audio preload="auto" ref={audioRef} id="certificateAudio">
        <source src="/img/certificate/blank.mp3" ref= {audioSourceRef} type="audio/mpeg"/>
        Your browser does not support the audio tag.

      </audio>
      {/*<div id="downloadLink"><img src="/img/certificate/download.svg" />Спампаваць сертыфікат</div>*/}
      <div ref={downloadLinkRef} style={{display: "none"}} id="certificateDownloadLink"><img src="/img/certificate/download.svg"  />cпампаваць сертыфікат</div>
      <div style={{fontFamily: "Rubik", visibility: "hidden"}}>TEST</div>
      <div style={{display: "none"}} ref={notADonorDivRef} className="not-a-donor-yet">
        <h1>Давайце разам адраджаць беларускую мову!</h1>
        <p> Каб атрымаць сертыфікат донара мовы трэба мінімум <a href="/be/speak">агучыць</a> 10 сказаў, або  <a href="/be/listen">праслухаць/праверыць</a> 20.</p>
        <p> Сертыфікат ганаровага донара мовы - агучыць 1000, альбо праверыць 3000, ці актыўна ўдзельнічаць у валанцёрскай дзейнасці.</p>
        <p>Калі вы ўжо зрабілі шмат для беларускай мовы, напрыклад, напісалі беларускую кнігу, або актыўна ўдзельнічаеце ў беларускамоўных ініцыятывах - напішыце
          нам пра гэта на say@say.by і мы з задавальненнем пазначым вас як "ганаровы донар мовы".</p>
        <p>Калі вы - вядомая медіа персона і хочаце дапамагчы Беларусі з роднай мовай - станьце нашым амбасадаром. Пішыце ў дырэкт у Інстаграм <a href="https://instagram.com/say.belarus">@say.belarus</a> або на пошту say@say.by.</p>
      </div>
      <div style={{display: "none"}} ref={notAllowedToViewDivRef} className="not-allowed-to-view-hidden">
        Профіль схаваны, або яго не існуе.
      </div>
      <div style={{display: "block"}} ref={loadingDivRef} className="not-allowed-to-view-hidden">
        Рыхтуем сертыфікат донара...
      </div>
    </div>
  );
}

export default connect<PropsFromState>(mapStateToProps)(PublicProfile);
