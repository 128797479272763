import * as React from 'react';

import './pill.css';

const { Tooltip } = require('react-tippy');
export type PillStatus = 'active' | 'done' | 'pending';

export default ({
  className = '',
  children,
  isOpen,
  num,
  onClick,
  openable = false,
  status,
  style,
  clip,
}: {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  num: number;
  openable?: boolean;
  onClick: () => any;
  status: PillStatus;
  style?: any;
  clip?: any;
}) => {
  const recogniseSpeechStatusFull = clip?.recogniseSpeechStatus;
  let recogniseSpeechStatus = "";
  let recognisedText = "";
  if(recogniseSpeechStatusFull) {
    if(recogniseSpeechStatusFull.indexOf('|') !== -1) {
      let recogniseOptions = recogniseSpeechStatusFull.split('|');
      recogniseSpeechStatus = recogniseOptions[0];
      recognisedText = recogniseOptions[1];
    } else {
      recogniseSpeechStatus = recogniseSpeechStatusFull;
    }
  }
  let didntHearWellTip = "";
  let allOkTip = "Кампутар вас зразумеў слова ў слова!";
  if (recogniseSpeechStatus === "result0" && recognisedText) {
    didntHearWellTip = "Звярніце увагу на гэты запіс, праслухайце самастойна. Кампутар вас не зразумеў, але ён яшчэ вучыцца, таму можа памыляцца. Ён пачуў: " + recognisedText;
  } else {
    didntHearWellTip = "Не атрымалася праверыць запіс аўтаматчна, праслухайце яго, калі ласка, самастойна.";
  }

  return (
    <div
      className={['pill', isOpen ? 'open' : '', status, className].join(' ')}
      onClick={
        openable
          ? event => {
              if (status === 'pending') return;
              event.stopPropagation();
              if (isOpen) return;
              onClick();
            }
          : null
      }
      style={style}>
      <div className="contents">{children}</div>
      {recogniseSpeechStatus ? (
        <div className="recogniseSpeechStatus">
          {recogniseSpeechStatus === 'result0' && (
            <Tooltip arrow title={didntHearWellTip}>
              <img
                className="speechCheck"
                src={require('../../ui/icons/question.svg')}
                alt="Success"
              />
            </Tooltip>
          )}
          {recogniseSpeechStatus === 'result100' && (
            <Tooltip
              arrow
              title={allOkTip}>
              <img
                className="speechCheck"
                src={require('../../ui/icons/done.svg')}
                alt="Success"
              />
            </Tooltip>
          )}
          {recogniseSpeechStatus === 'loading' && (
            <Tooltip arrow title="Аўтаматычная праверка запісу сістэмай распазнавання, якая трэніравалася дзякуючы галасам донараў мовы, такіх як вы!">
              <img
                className="speechCheck"
                src={require('../../ui/icons/wait.svg')}
                alt="Success"
              />
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="num">{num}</div>
      )}
    </div>
  );
};
