import * as React from 'react';
import { LocaleLink } from '../locale-helpers';

export default (props: { reverse?: boolean }) => {
  const imgSrc = props.reverse
    ? require('../../donor/components/ui/icons/donar-by-logo-white.svg')
    // ? require('../../donor/components/ui/icons/say-by-top-logo.png')
    : require('../../donor/components/ui/icons/donar-by-logo-bird-y.svg');
    // : require('../../donor/components/ui/icons/donor-by-logo-red-black.svg');

  return (
    <LocaleLink className="main-logo" to="">
      <img className="main-donor-logo" src={imgSrc} />
    </LocaleLink>
  );
};
