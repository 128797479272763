export const SUBREGIONS: any = {
  "MINSK": {
    "MINSK-00050": "Прыгарад і раён",
    "MINSK-00100": "Абсерваторыя",
    "MINSK-00200": "Акадэмія Навук",
    "MINSK-00300": "Алімпійская вёска",
    "MINSK-00400": "Алтайская",
    "MINSK-00500": "Амурская",
    "MINSK-00600": "Ангарская",
    "MINSK-00700": "Антонаўская-Захарава",
    "MINSK-00800": "Аркадзія",
    "MINSK-00900": "Арлоўка",
    "MINSK-01000": "Асмолаўка",
    "MINSK-01100": "Аўтаз",
    "MINSK-01200": "Байкальская",
    "MINSK-01300": "Баравая",
    "MINSK-01400": "Бараўляны",
    "MINSK-01500": "Белая Вежа",
    "MINSK-01600": "Бельскага",
    "MINSK-01700": "Брама Мінска",
    "MINSK-01800": "Брылевічы",
    "MINSK-01900": "Бяды",
    "MINSK-02000": "Вадалажскага",
    "MINSK-02100": "Валгаградская",
    "MINSK-02200": "Велазавод",
    "MINSK-02300": "Вівальдзі",
    "MINSK-02400": "Ваенны гарадок",
    "MINSK-02500": "Возера",
    "MINSK-02600": "Вольная біржа",
    "MINSK-02700": "Вышалескага",
    "MINSK-02800": "Вяснянка",
    "MINSK-02900": "Вячэрні Мінск",
    "MINSK-03000": "Гатава",
    "MINSK-03100": "Гая",
    "MINSK-03200": "Галадзеда",
    "MINSK-03300": "Громава",
    "MINSK-03400": "Грушаўка / Чыгуначная",
    "MINSK-03500": "Дамброўка",
    "MINSK-03600": "Дзявяты км.",
    "MINSK-03700": "Дражня",
    "MINSK-03800": "Дразды",
    "MINSK-03900": "ДС Адоеўскага",
    "MINSK-04000": "Дынама",
    "MINSK-04100": "ЖК Мармелад",
    "MINSK-04200": "Жудро",
    "MINSK-04300": "Завадскі",
    "MINSK-04400": "Залатая горка",
    "MINSK-04500": "Захад",
    "MINSK-04600": "Захарава-Пуліхава",
    "MINSK-04700": "Зацань",
    "MINSK-04800": "Змена",
    "MINSK-04900": "Зялёны Луг",
    "MINSK-05000": "Інстытут Культуры",
    "MINSK-05100": "Каліноўскі",
    "MINSK-05200": "Кальварыя",
    "MINSK-05300": "Камароўка",
    "MINSK-05400": "Камвольны",
    "MINSK-05500": "Каменная Горка",
    "MINSK-05600": "Караля",
    "MINSK-05700": "Карастаянавай",
    "MINSK-05800": "Каскад",
    "MINSK-05900": "Кашары-Ляхаўка",
    "MINSK-06000": "Кедышкі",
    "MINSK-06100": "Кіеўскі сквер",
    "MINSK-06200": "Кісялёва",
    "MINSK-06300": "Коласа",
    "MINSK-06400": "Коласа, Чорнага, Чарнышеўскага",
    "MINSK-06500": "Калеснікава",
    "MINSK-06550": "Копішча",
    "MINSK-06600": "Корш-Сабліна",
    "MINSK-06700": "Кунцаўшчына",
    "MINSK-06800": "Курасоўшчына",
    "MINSK-06900": "Лебядзіны",
    "MINSK-07000": "Леніна",
    "MINSK-07100": "Лошыца",
    "MINSK-07200": "Магістар",
    "MINSK-07300": "Магістр, Дзіцячы",
    "MINSK-07400": "Малінаўка",
    "MINSK-07500": "Малое Сціклева",
    "MINSK-07600": "Маскоўская",
    "MINSK-07700": "Маскоўскі раён",
    "MINSK-07800": "Масюкоўшчына",
    "MINSK-07900": "Маяк",
    "MINSK-08000": "Маякоўка",
    "MINSK-08100": "Маякоўскага",
    "MINSK-08200": "Мегаполіс",
    "MINSK-08300": "Мядзвежына",
    "MINSK-08400": "Мінск Мір",
    "MINSK-08500": "Мінск-Сіці",
    "MINSK-08600": "Мірашнічэнка",
    "MINSK-08700": "Міхалова",
    "MINSK-08800": "Мяснікова",
    "MINSK-08900": "Навінкі",
    "MINSK-09000": "Несцерка",
    "MINSK-09100": "Няміга",
    "MINSK-09200": "Новая панарама",
    "MINSK-09300": "Новая Баравая",
    "MINSK-09400": "Панамарэнкі",
    "MINSK-09500": "Парнікі",
    "MINSK-09600": "Партызанская",
    "MINSK-09700": "Паўднёвы",
    "MINSK-09800": "Паўднёвы Захад",
    "MINSK-09900": "Паўночны пасёлак",
    "MINSK-10000": "Першамайскі",
    "MINSK-10100": "Плошча Коласа",
    "MINSK-10200": "Плошча Перамогі",
    "MINSK-10400": "Праспект Газеты Звязда",
    "MINSK-10500": "Пушкінская",
    "MINSK-10600": "Пярэспа",
    "MINSK-10700": "Пятроўшчына",
    "MINSK-10800": "Ракаўскае прадмесце",
    "MINSK-10900": "Ракаўская шаша",
    "MINSK-11000": "Раманаўская Слабада",
    "MINSK-11050": "Ратамка",
    "MINSK-11100": "Розачка",
    "MINSK-11200": "Рыга / Бангалор",
    "MINSK-11300": "Сакалянка",
    "MINSK-11400": "Саламяная",
    "MINSK-11500": "Салон Пракату",
    "MINSK-11600": "Салют",
    "MINSK-11700": "Севастопальскі парк",
    "MINSK-11800": "Сельгас пасёлак",
    "MINSK-11900": "Семяняка",
    "MINSK-12000": "Серабранка",
    "MINSK-12100": "Сердзіча",
    "MINSK-12200": "Сярова",
    "MINSK-12300": "Скрыпнікава",
    "MINSK-12400": "Слабадская",
    "MINSK-12500": "Сляпянка",
    "MINSK-12600": "Сокал",
    "MINSK-12700": "Сосны",
    "MINSK-12800": "Спартовая",
    "MINSK-12900": "Старажоўская",
    "MINSK-13000": "Стары аэрапорт",
    "MINSK-13100": "Стэла",
    "MINSK-13200": "Сухарава",
    "MINSK-13300": "Сцяпянка",
    "MINSK-13400": "Трактаразавадскі пасёлак",
    "MINSK-13500": "Тучынка",
    "MINSK-13600": "Уручча",
    "MINSK-13700": "Усход",
    "MINSK-13800": "Усходні",
    "MINSK-13900": "Усходняя вуліца",
    "MINSK-13950": "Харкаўская",
    "MINSK-14000": "Харужай",
    "MINSK-14100": "Цівалі",
    "MINSK-14200": "Цна",
    "MINSK-14300": "Цнянка",
    "MINSK-14400": "Цэнтр",
    "MINSK-14500": "Чайкінай",
    "MINSK-14600": "Чалюскінцаў",
    "MINSK-14700": "Чарвякова, Кахоўская, Сморгаўскі тракт",
    "MINSK-14800": "Чкалава",
    "MINSK-14900": "Чкалаўскі",
    "MINSK-15000": "Чыжоўка",
    "MINSK-15100": "Чырвоны Бор",
    "MINSK-15200": "Шабаны",
    "MINSK-15300": "Шарыкі",
    "MINSK-15400": "Шаўчэнка",
    "MINSK-15500": "Шахматны",
    "MINSK-15600": "Шпалерная",
    "MINSK-15700": "Шугаева",
    "MINSK-15800": "Шчотаўка",
    "MINSK-15900": "Юбілейка",
    "MINSK-16000": "Юхнаўка",
    "MINSK-16100": "Янкі Маўра",
    "MINSK-16200": "Ясеніна"
  },
  "BREST": {
    "BREST-00050": "Прыгарад і раён",
    "BREST-00100": "Бярозаўка",
    "BREST-00200": "Валынка",
    "BREST-00300": "Вараўскога",
    "BREST-00400": "Вулька",
    "BREST-00500": "Героеўка",
    "BREST-00600": "Дуброўка",
    "BREST-00700": "Заводскі раён",
    "BREST-00800": "Задворцы",
    "BREST-01000": "Кавалёўка",
    "BREST-01100": "Касманаўтаў",
    "BREST-01200": "Кіеўка",
    "BREST-01300": "Набярэжная",
    "BREST-01400": "Паўднёвы",
    "BREST-01500": "Плоска",
    "BREST-01600": "Порт",
    "BREST-01700": "Пушка",
    "BREST-01800": "Усход",
    "BREST-01900": "Цеплаэлектрацэнтральны",
    "BREST-02000": "Цэнтр"
  },
  "VICIEBSK": {
    "VICIEBSK-00050": "Прыгарад і раён",
    "VICIEBSK-00100": "Білева",
    "VICIEBSK-00200": "Гагарына",
    "VICIEBSK-00300": "ДБК",
    "VICIEBSK-00400": "Журжава",
    "VICIEBSK-00500": "Кастрычніцкі раён",
    "VICIEBSK-00600": "Лучоса",
    "VICIEBSK-00700": "Маркаўшчына",
    "VICIEBSK-00800": "Медцэнтр",
    "VICIEBSK-00900": "Паўднёвыя раёны ля возера Бегемот",
    "VICIEBSK-01000": "Поўдзень",
    "VICIEBSK-01100": "Тарны",
    "VICIEBSK-01200": "Черняхоўка",
    "VICIEBSK-01300": "Чыгуначны"
  },
  "HOMIEL": {
    "HOMIEL-00050": "Прыгарад і раён",
    "HOMIEL-00100": "5-ы мікрараён",
    "HOMIEL-00200": "51 мікрараён",
    "HOMIEL-00300": "Валатава",
    "HOMIEL-00400": "Заходні",
    "HOMIEL-00500": "Кастрычнік",
    "HOMIEL-00600": "Мельнікаў луг",
    "HOMIEL-00700": "Навабеліца",
    "HOMIEL-00750": "Новае жыццё",
    "HOMIEL-00800": "Паўднёвы",
    "HOMIEL-00900": "Перамогі",
    "HOMIEL-01000": "Сельмаш",
    "HOMIEL-01100": "Сонечны",
    "HOMIEL-01200": "Стары Аэрадром",
    "HOMIEL-01300": "Фестывальны",
    "HOMIEL-01400": "Шведская горка"
  },
  "HRODNA": {
    "HRODNA-00050": "Прыгарад і раён",
    "HRODNA-00100": "Альшанка",
    "HRODNA-00200": "Вішнявец",
    "HRODNA-00300": "Вясенні",
    "HRODNA-00350": "Грандзічы",
    "HRODNA-00400": "Дзевятоўка",
    "HRODNA-00500": "Залатая горка",
    "HRODNA-00600": "Ласосна",
    "HRODNA-00700": "Паўднёвы",
    "HRODNA-00800": "Пентагон",
    "HRODNA-00900": "Прынеманскі",
    "HRODNA-01000": "Пушкіна",
    "HRODNA-01100": "Румлёва",
    "HRODNA-01200": "Фарты",
    "HRODNA-01300": "Фолюш"
  },
  "MAHILIOU": {
    "MAHILIOU-00050": "Прыгарад і раён",
    "MAHILIOU-00100": "30 год перамогі",
    "MAHILIOU-00200": "Восьмы цагляны",
    "MAHILIOU-00300": "Казіміраўка",
    "MAHILIOU-00400": "Космас",
    "MAHILIOU-00500": "Непакоранных",
    "MAHILIOU-00600": "Раён ЖД вакзала",
    "MAHILIOU-00700": "Спадарожнік",
    "MAHILIOU-00800": "Фаціна",
    "MAHILIOU-00900": "Цэнтр",
    "MAHILIOU-01000": "Юбілейны",
    "MAHILIOU-01100": "Якубоўскага",
    "MAHILIOU-01200": "Ямніцкі флот"
  },
  "BY": {
    "BY-00100": "Акцябрскі",
    "BY-00200": "Асіповічы",
    "BY-00300": "Астравец",
    "BY-00400": "Ашмяны",
    "BY-00500": "Бабруйск",
    "BY-00600": "Баранавічы",
    "BY-00700": "Барань",
    "BY-00800": "Барысаў",
    "BY-00900": "Белаазёрск",
    "BY-01000": "Беразіно",
    "BY-01100": "Браслаў",
    // "BY-01200": "Брэст",
    "BY-01300": "Буда-Кашалёва",
    "BY-01400": "Быхаў",
    "BY-01500": "Бялынічы",
    "BY-01600": "Бяроза",
    "BY-01700": "Бярозаўка",
    "BY-01800": "Валожын",
    "BY-01900": "Васілевічы",
    "BY-02000": "Ваўкавыск",
    "BY-02100": "Верхнядзвінск",
    "BY-02200": "Ветка",
    "BY-02300": "Вілейка",
    // "BY-02400": "Віцебск",
    "BY-02500": "Высокае",
    "BY-02600": "Ганцавічы",
    "BY-02700": "Гарадок",
    "BY-02750": "Глуск",
    "BY-02800": "Глыбокае",
    // "BY-02900": "Гомель",
    "BY-03000": "Горкі",
    // "BY-03100": "Гродна",
    "BY-03200": "Давыд-Гарадок",
    "BY-03300": "Дзісна",
    "BY-03400": "Дзяржынск",
    "BY-03500": "Дзятлава",
    "BY-03600": "Добруш",
    "BY-03700": "Докшыцы",
    "BY-03800": "Драгічын",
    "BY-03900": "Дуброўна",
    "BY-04000": "Ельск",
    "BY-04100": "Жабінка",
    "BY-04200": "Жлобін",
    "BY-04300": "Жодзіна",
    "BY-04400": "Жыткавічы",
    "BY-04500": "Заслаўе",
    "BY-04600": "Іванава",
    "BY-04700": "Івацэвічы",
    "BY-04800": "Іўе",
    "BY-04900": "Калінкавічы",
    "BY-05000": "Каменец",
    "BY-05100": "Капыль",
    "BY-05200": "Касцюковічы",
    "BY-05300": "Кіраўск",
    "BY-05400": "Клецк",
    "BY-05500": "Клімавічы",
    "BY-05600": "Клічаў",
    "BY-05700": "Кобрын",
    "BY-05800": "Косава",
    "BY-05850": "Краснаполле",
    "BY-05900": "Круглае",
    "BY-06000": "Крупкі",
    "BY-06100": "Крычаў",
    "BY-06200": "Лагойск",
    "BY-06300": "Лепель",
    "BY-06400": "Ліда",
    "BY-06450": "Лоеў",
    "BY-06500": "Лунінец",
    "BY-06600": "Любань",
    "BY-06700": "Ляхавічы",
    // "BY-06800": "Магілёў",
    "BY-06900": "Мазыр",
    "BY-07000": "Маладзечна",
    "BY-07100": "Маларыта",
    "BY-07200": "Мар'інаГорка",
    "BY-07300": "Масты",
    "BY-07400": "Мёры",
    "BY-07500": "Мікашэвічы",
    // "BY-07600": "Мінск",
    "BY-07700": "Мсціслаў",
    "BY-07800": "Мядзел",
    "BY-07900": "Навагрудак",
    "BY-08000": "Наваполацк",
    "BY-08100": "Нароўля",
    "BY-08200": "Новалукомль",
    "BY-08300": "Нясвіж",
    "BY-08400": "Орша",
    "BY-08500": "Паставы",
    "BY-08600": "Петрыкаў",
    "BY-08700": "Пінск",
    "BY-08800": "Полацк",
    "BY-08900": "Пружаны",
    "BY-09000": "Рагачоў",
    "BY-09100": "Рэчыца",
    "BY-09200": "Салігорск",
    "BY-09300": "Светлагорск",
    "BY-09400": "Свіслач",
    "BY-09500": "Скідзель",
    "BY-09600": "Слаўгарад",
    "BY-09700": "Слонім",
    "BY-09800": "Слуцк",
    "BY-09900": "Смалявічы",
    "BY-10000": "Смаргонь",
    "BY-10100": "СтарыяДарогі",
    "BY-10200": "Столін",
    "BY-10300": "Стоўбцы",
    "BY-10400": "Сянно",
    "BY-10500": "Талачын",
    "BY-10600": "Тураў",
    "BY-10700": "Узда",
    "BY-10800": "Фаніпаль",
    "BY-10900": "Хойнікі",
    "BY-11000": "Чавусы",
    "BY-11100": "Чачэрск",
    "BY-11200": "Чашнікі",
    "BY-11300": "Чэрвень",
    "BY-11400": "Чэрыкаў",
    "BY-11500": "Шклоў",
    "BY-11600": "Шчучын",
  }
};

export const REGIONS:any = {
  "MINSK": "Мінск",
  "BREST": "Брэст",
  "VICIEBSK": "Віцебск",
  "HOMIEL": "Гомель",
  "HRODNA": "Гродна",
  "MAHILIOU": "Магілёў",
  "BY": "Беларусь",
  "AF": "Афганістан",
  "AX": "Аландскія астравы",
  "AL": "Албанія",
  "DZ": "Алжыр",
  "AS": "Амерыканскае Самоа",
  "AD": "Андора",
  "AO": "Ангола",
  "AI": "Ангілья",
  "AQ": "Антарктыда",
  "AG": "Антыгуа і Барбуда",
  "AR": "Аргенціна",
  "AM": "Арменія",
  "AW": "Аруба",
  "AU": "Аўстралія",
  "AT": "Аўстрыя",
  "AZ": "Азербайджан",
  "BS": "Багамскія астравы",
  "BH": "Бахрэйн",
  "BD": "Бангладэш",
  "BB": "Барбадас",
  "be": "Бельгія",
  "BZ": "Беліз",
  "BJ": "Бенін",
  "BM": "Бермуды",
  "BT": "Бутан",
  "BO": "Балівія",
  "BA": "Боснія і Герцагавіна",
  "BW": "Батсвана",
  "BV": "Востраў Буве",
  "BR": "Бразілія",
  "IO": "Брытанская тэрыторыя ў Індыйскім акіяне",
  "CC": "Какосавыя (Кілінг) астравы",
  "BG": "Балгарыя",
  "BF": "Буркіна-Фасо",
  "BI": "Бурундзі",
  "KH": "Камбоджа",
  "CM": "Камерун",
  "CA": "Канада",
  "CV": "Каба-Вэрдэ",
  "KY": "Кайманавы астравы",
  "CF": "Цэнтральна-Афрыканская Рэспубліка",
  "TD": "Чад",
  "CL": "Чылі",
  "CN": "Кітай",
  "CX": "Востраў Калядаў",
  "CO": "Калумбія",
  "KM": "Каморскія выспы",
  "CG": "Конга",
  "CD": "Конга, Дэмакратычная Рэспубліка",
  "CK": "Астравы Кука",
  "CR": "Коста-Рыка",
  "CI": "Кот-д'Івуар",
  "HR": "Харватыя",
  "CU": "Куба",
  "CY": "Кіпр",
  "CZ": "Чэхія",
  "DK": "Данія",
  "DJ": "Джыбуці",
  "DM": "Дамініка",
  "DO": "Дамініканская Рэспубліка",
  "EC": "Эквадор",
  "EG": "Егіпет",
  "SV": "Сальвадор",
  "GQ": "Экватарыяльная Гвінея",
  "ER": "Эрытрэя",
  "EE": "Эстонія",
  "ET": "Эфіопія",
  "FK": "Фалклендскія астравы (Мальвінскія)",
  "FO": "Фарэрскія астравы",
  "FJ": "Фіджы",
  "FI": "Фінляндыя",
  "FR": "Францыя",
  "GF": "Французская Гвіяна",
  "PF": "Французская Палінезія",
  "TF": "Паўднёвыя тэрыторыі Францыі",
  "GA": "Габон",
  "GM": "Гамбія",
  "GE": "Грузія",
  "DE": "Германія",
  "GH": "Гана",
  "GI": "Гібралтар",
  "GR": "Грэцыя",
  "GL": "Грэнландыя",
  "GD": "Грэнада",
  "GP": "Guadeloupe",
  "GU": "Гуам",
  "GT": "Гватэмала",
  "GG": "Гернсі",
  "GN": "Гвінея",
  "GW": "Гвінея-Бісаў",
  "GY": "Гаяна",
  "HT": "Гаіці",
  "HM": "Астравы Херд і Макдональд",
  "VA": "Святы Пасад (дзяржава Ватыкан)",
  "HN": "Гандурас",
  "HK": "Ганконг",
  "HU": "Венгрыя",
  "IS": "Ісландыя",
  "IN": "Індыя",
  "ID": "Інданезія",
  "IR": "Іран, Ісламская Рэспубліка",
  "IQ": "Ірак",
  "IE": "Ірландыя",
  "IM": "Востраў Мэн",
  "IL": "Ізраіль",
  "IT": "Італія",
  "JM": "Ямайка",
  "JP": "Японія",
  "JE": "Джэрсі",
  "JO": "Іарданія",
  "KZ": "Казахстан",
  "KE": "Кенія",
  "KI": "Кірыбаці",
  "KP": "Карэя, Народна-Дэмакратычная Рэспубліка",
  "KR": "Карэя, Рэспубліка",
  "KW": "Кувейт",
  "KG": "Кыргызстан",
  "LA": "Лаоская Народна-Дэмакратычная Рэспубліка",
  "LV": "Латвія",
  "LB": "Ліван",
  "LS": "Лесота",
  "LR": "Ліберыя",
  "LY": "Лівійская Арабская Джамахірыя",
  "LI": "Ліхтэнштэйн",
  "LT": "Літва",
  "LU": "Люксембург",
  "MO": "Macao",
  "MK": "Македонія, Былая Югаслаўская Рэспубліка",
  "MG": "Мадагаскар",
  "MW": "Малаві",
  "MY": "Малайзія",
  "MV": "Мальдывы",
  "ML": "Mali",
  "MT": "Мальта",
  "MH": "Маршалавы выспы",
  "MQ": "Марцініка",
  "MR": "Маўрытанія",
  "MU": "Маўрыкій",
  "YT": "Маёта",
  "MX": "Мексіка",
  "FM": "Мікранезія, Федэратыўныя Штаты",
  "MD": "Малдова, Рэспубліка",
  "MC": "Манака",
  "MN": "Манголія",
  "MS": "Montserrat",
  "MA": "Марока",
  "MZ": "Мазамбік",
  "MM": "М'янма",
  "NA": "Намібія",
  "NR": "Науру",
  "NP": "Непал",
  "NL": "Нідэрланды",
  "AN": "Нідэрландскія Антыльскія астравы",
  "NC": "Новая Каледонія",
  "NZ": "Новая Зеландыя",
  "NI": "Нікарагуа",
  "NE": "Нігер",
  "NG": "Нігерыя",
  "NU": "Ніуе",
  "NF": "Востраў Норфалк",
  "MP": "Паўночныя Марыянскія астравы",
  "NO": "Нарвегія",
  "OM": "Аман",
  "PK": "Пакістан",
  "PW": "Палаў",
  "PS": "Палестынская тэрыторыя, акупаваная",
  "PA": "Панама",
  "PG": "Папуа-Новая Гвінея",
  "PY": "Парагвай",
  "PE": "Перу",
  "PH": "Філіпіны",
  "PN": "Піткэрн",
  "PL": "Польшча",
  "PT": "Партугалія",
  "PR": "Пуэрта-Рыка",
  "QA": "Катар",
  "RE": "Reunion",
  "RO": "Румынія",
  "RU": "Расійская Федэрацыя",
  "RW": "Руанда",
  "SH": "Святая Алена",
  "KN": "Сент-Кітс і Нэвіс",
  "LC": "Сент-Люсія",
  "PM": "Сент-П'ер і Мікелон",
  "VC": "Сент-Вінсэнт і Грэнадыны",
  "WS": "Samoa",
  "SM": "Сан-Марына",
  "ST": "Сан-Томе і Прынсіпі",
  "SA": "Саудаўская Аравія",
  "SN": "Сенегал",
  "CS": "Сербія і Чарнагорыя",
  "SC": "Сейшэльскія выспы",
  "SL": "Сьера-Леонэ",
  "SG": "Сінгапур",
  "SK": "Славакія",
  "SI": "Славенія",
  "SB": "Саламонавы Астравы",
  "SO": "Самалі",
  "ZA": "Паўднёвая Афрыка",
  "GS": "Паўднёвая Джорджыя і Паўднёвыя Сандвічавыя астравы",
  "ES": "Іспанія",
  "LK": "Шры-Ланка",
  "SD": "Судан",
  "SR": "Сурынам",
  "SJ": "Шпіцберген і Ян-Маен",
  "SZ": "Свазіленд",
  "SE": "Швецыя",
  "CH": "Швейцарыя",
  "SY": "Сірыйская Арабская Рэспубліка",
  "TW": "Тайвань, правінцыя Кітая",
  "TJ": "Таджыкістан",
  "TZ": "Танзанія, Аб'яднаная Рэспубліка",
  "TH": "Тайланд",
  "TL": "Тымор-Лешці",
  "TG": "Того",
  "TK": "Такелау",
  "TO": "Тонга",
  "TT": "Трынідад і Табага",
  "TN": "Туніс",
  "TR": "Турцыя",
  "TM": "Туркменістан",
  "TC": "Астравы Тэркс і Кайкас",
  "TV": "Tuvalu",
  "UG": "Уганда",
  "UA": "Украіна",
  "AE": "Аб'яднаныя Арабскія Эміраты",
  "GB": "Вялікабрытанія",
  "US": "Злучаныя Штаты",
  "UM": "Малыя аддаленыя астравы Злучаных Штатаў",
  "UY": "Уругвай",
  "UZ": "Узбекістан",
  "VU": "Вануату",
  "VE": "Венесуэла",
  "VN": "В'етнам",
  "VG": "Віргінскія астравы, Брытанскія",
  "VI": "Віргінскія астравы, ЗША",
  "WF": "Уоліс і Футуна",
  "EH": "Заходняя Сахара",
  "YE": "Емен",
  "ZM": "Замбія",
  "ZW": "Зімбабвэ"
};
