import * as React from 'react';
import { useRef, useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import API from '../../../services/api';
import DonorAPI from '../../services/donor-api';
import {DonorPublicProfile} from '../../services/donor-api';
import StateTree from '../../../stores/tree';
import {REGIONS, SUBREGIONS} from '../../stores/region';

import { useAccount } from '../../../hooks/store-hooks';   

import './public-profile.css';
import {async} from "q";

interface PropsFromState {
  api: API;
}
type State = {
  count: number;
  api:API;
}

const mapStateToProps = ({ api }: StateTree) => ({
  api
});
var apiInstance:API;
class HandleCertificateShow extends React.Component<
    {
      //donorId: number
    } & PropsFromState,
    State
    > {
  render () {
      apiInstance = this.props.api;


      // console.log(this.props.api);

      // let honoredLists = await this.props.api.donorAPI.fetchHonoredList();
    return (
            <CheckRedirects />
    );
  }
  componentDidMount(){
     //check if we are logged in
    
   //  if(account && account.username) {
    //    alert('Залагінілі');
     // } else {
      // window.location.href = "/login";
     //}
  }
}

function CheckRedirects() {
    const account = useAccount();


    // var self = this;
    // console.log(this.props)
    // const { api, type } = this.props;

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        // console.log(urlParams);
        const isLucky = urlParams.get('isLucky');

        // let honoredLists = self.props.api.donorAPI.fetchHonoredList();

        if(isLucky && isLucky!=="false") {
            // apiInstance.donorAPI.fetchAvailablePrizes(await fetch("https://say.by/get-course-name-by-run/621321881cde39b096201fc9"););
            // alert('Lucky');
            const courseRunId = urlParams.get("courseRunId");
            //getAvailablePrices?courseRunId
            //choosePrize?courseRunId&prizeId=123
            window.location.href = "https://say.by/donor-prize/?courseRunId=" + courseRunId + "&token=" + urlParams.get("token") + "&luckyDetails=" + urlParams.get("luckyDetails");
        } else {
            // alert('Not Lucky');

            if(!account) {
                sessionStorage.setItem('redirectURL', '/be/show-certificate');
                // sessionStorage.removeItem('redirectURL');
                window.location.href = "/login";
                document.getElementById("waitingDiv").innerHTML = "Патрэбна залагініцца яшчэ разочак. Чакайце... ";
            } else if(account && account.username) {
                 window.location.href = "/" + convertIdToDonorNumber(account.donor_id);
            } else if(account && !account.username) {
                alert('no username yet');
            }
        }
    });
    return (
      <div style={{"width":"100%", "textAlign": "center", "marginTop":"5px", "fontSize":"1em"}} id="waitingDiv">Секундачку...</div>
    );
} 

function convertIdToDonorNumber(donorId: number)
{
  return donorId.toString().padStart(7, "0");
}

export default connect<PropsFromState>(mapStateToProps)(HandleCertificateShow);