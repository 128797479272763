import {
  Localized,
  withLocalization,
  WithLocalizationProps,
} from '@fluent/react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { trackRecording } from '../../../../services/tracker';
import { useLocale } from '../../../locale-helpers';
import {
  CloudIcon,
  PlayOutlineIcon,
  RedoIcon,
  ShareIcon,
  StopIcon,
} from '../../../ui/icons';
import { getAudioFormat, canPlayMp4 } from '../../../../utility';
import { ContributionPillProps } from '../contribution';
import Pill, { PillStatus } from '../pill';
import { SentenceRecording } from './sentence-recording';

import './recording-pill.css';

const { Tooltip } = require('react-tippy');

interface Props extends ContributionPillProps, WithLocalizationProps {
  children?: React.ReactNode;
  clip: SentenceRecording;
  onRerecord: () => any;
  status: PillStatus;
}
//donar.by extension
function getFileNameForDownload(clip: SentenceRecording) {
  let name = 'donar-by-' + clip.sentence.text.substring(0, 15);
  if (getAudioFormat() === 'audio/wav') {
    // @ts-ignore
    if (canPlayMp4() && clip.recording.mp4Url) {
      return name + '.mp4';
    } else {
      return name + '.wav';
    }
  } else if (getAudioFormat().indexOf('ogg') !== -1) {
    return name + '.ogg';
  } else {
    return name + '.mp3';
  }
}
//donar.by extension
function getUsedBlobUrl(clip: SentenceRecording) {
  // @ts-ignore
  if (
    getAudioFormat() === 'audio/wav' &&
    canPlayMp4() &&
    clip.recording?.mp4Url
  ) {
    // @ts-ignore
    return clip.recording.mp4Url;
  } else {
    return clip.recording.url;
  }
}

function RecordingPill({
  children,
  clip,
  getString,
  onRerecord,
  onShare,
  status,
  ...props
}: Props) {
  const [locale] = useLocale();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showSentenceTooltip, setShowSentenceTooltip] = useState(false);
  const audioRef = useRef(null);

  const toggleIsPlaying = () => {
    const { current: audio } = audioRef;
    let nextIsPlaying = !isPlaying;
    if (nextIsPlaying) {
      trackRecording('listen', locale);
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
      setShowSentenceTooltip(false);
    }
    setIsPlaying(nextIsPlaying);
  };

  return (
    <Pill {...props} className="recording" openable clip={clip} status={status}>
      {children}

      {!children && status === 'active' && (
        <Localized id="record-cta">
          <div className="text" />
        </Localized>
      )}

      {!children && status === 'done' && (
        <>
          <audio preload="auto" onEnded={toggleIsPlaying} ref={audioRef}>
            {
              /*donar.by change - when it is a wav format and we can play mp4 - then do it and it will fix the issue with IOS 16 */
              // @ts-ignore
              getAudioFormat() === 'audio/wav' &&
              canPlayMp4() &&
              clip.recording.mp4Url ? (
                // @ts-ignore
                <source src={clip.recording.mp4Url} type="audio/mp4" />
              ) : (
                <source src={clip.recording.url} type={getAudioFormat()} />
              )
            }
          </audio>
          <Tooltip
            arrow
            open={isPlaying || showSentenceTooltip}
            theme="grey-tooltip"
            title={clip.sentence.text}>
            <button
              className="play"
              type="button"
              onClick={toggleIsPlaying}
              onMouseEnter={() => setShowSentenceTooltip(true)}
              onMouseLeave={() => setShowSentenceTooltip(false)}>
              <span className="padder">
                {isPlaying ? <StopIcon /> : <PlayOutlineIcon />}
              </span>
            </button>
          </Tooltip>
          {isPlaying ? (
            <div className="placeholder" />
          ) : (
            <>
              <Tooltip arrow title={getString('review-tooltip')}>
                <button className="redo" type="button" onClick={onRerecord}>
                  <span className="padder">
                    <RedoIcon />
                  </span>
                </button>
              </Tooltip>
              {/*<Tooltip arrow title={getString('share-clip')}>*/}
              <Tooltip arrow title="Спампаваць ваш запіс">
                {/*<button className="share" type="button" onClick={onShare}>*/}
                {/*  <span className="padder">*/}
                {/*    <ShareIcon />*/}
                {/*  </span>*/}
                {/*</button>*/}
                {/*Modification by donar.by*/}
                <a
                  className="voice-download-pill padder"
                  title="спампаваць"
                  target="_blank"
                  download={getFileNameForDownload(clip)}
                  href={getUsedBlobUrl(clip)}>
                  <CloudIcon />
                </a>
              </Tooltip>
            </>
          )}
        </>
      )}
    </Pill>
  );
}

export default withLocalization(RecordingPill);
