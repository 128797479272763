import * as React from 'react';
import { Localized } from '@fluent/react';
import { trackNav } from '../../services/tracker';
import URLS from '../../urls';
import ShareButtons from '../share-buttons/share-buttons';
import { ContactIcon, DiscourseIcon, SupportIcon } from '../ui/icons';
import { TextButton } from '../ui/ui';
import { LocaleLink, useLocale } from '../locale-helpers';
import Logo from './logo';
import SubscribeNewsletter from './subscribe-newsletter';
import { ContactLink, DiscourseLink, GitHubLink } from '../shared/links';

import './footer.css';

const LocalizedLocaleLink = ({ id, to }: { id: string; to: string }) => {
  const [locale] = useLocale();
  return (
    <Localized id={id}>
      <LocaleLink to={to} onClick={() => trackNav(id, locale)} />
    </Localized>
  );
};

export default React.memo(() => {
  const [locale] = useLocale();
  return (
    <footer>
      <div id="help-links">
        <a href="https://instagram.com/say.belarus/">
          <img src="/img/social-instagram.svg" />
          <div>наш інстаграм</div>
        </a>
        <div className="divider" />
        <a href="https://t.me/voice_by">
          <img src="/img/social-telegram.svg" />
          <div>чат для <br /> абмеркавання</div>
        </a>
        <div className="divider" />
        <a href="https://facebook.com/say.by.belarus">
          <img className="facebook-icon-footer" src="/img/social-facebook.svg" />
          <div>наша facebook <br /> старонка</div>
        </a>
      </div>
      <div id="moz-links" style={{paddingTop:"20px"}}>
        <div className="logo-container">
          <Logo reverse />
          <p className="license">
            <Localized
              id="content-license-text"
              elems={{
                licenseLink: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mozilla.org/en-US/foundation/licensing/website-content/"
                  />
                ),
              }}>
              <span />
            </Localized>
          </p>

        </div>
        <div className="divider-bottom" />
        <div className="links">
          <div>
            {/*<LocalizedLocaleLink id="privacy" to={URLS.PRIVACY} />*/}
            <a href="https://say.by/privacy" target="_blank"><Localized id="privacy" /></a>
            <LocalizedLocaleLink id="terms" to={URLS.TERMS} />
          </div>
          <div className="divider-vertical"/>
          <div>
            <LocalizedLocaleLink id="faq" to={URLS.FAQ} />
          </div>
        </div>

        <Localized id="back-top">
          <TextButton
            className="back-top"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </Localized>
      </div>
    </footer>
  );
});
