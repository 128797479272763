const MONTH_NAMES:any = {
  1: 'студзеня',
  2: 'лютага',
  3: 'сакавіка',
  4: 'красавіка',
  5: 'траўня',
  6: 'чэрвеня',
  7: 'ліпеня',
  8: 'жніўня',
  9: 'верасня',
  10: 'кастрычніка',
  11: 'лістапада',
  12: 'снежня',
}
export function getTimeRemaining(endtime: string): string {
  const total = Date.parse(endtime) - Date.now();
  const seconds = Math.floor( (total/1000) % 60 );
  const minutes = Math.floor( (total/1000/60) % 60 );
  const hours = Math.floor( (total/(1000*60*60)) % 24 );
  const days = Math.floor( total/(1000*60*60*24) );
  if(total < 0) {
    return "";
  } else {
    return days + ':' + ((hours < 10) ? "0" : "") + hours + ':' + ((minutes < 10) ? "0" : "") + minutes + ':' + ((seconds < 10) ? "0" : "") + seconds;
  }

  /*return {
    total,
    days,
    hours,
    minutes,
    seconds
  };*/
}

export function getMonday(d:Date|null = null):Date {
  if (d === null) {
    d = new Date();
  }
  let day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function getSunday(d:Date|null = null):Date {
  if(d === null) {
    d = new Date();
  }
  let day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6:1) + 6; // adjust when day is sunday
  return new Date(d.setDate(diff));
}
export function getMonthName(d:Date|null = null):string {
  if(d === null) {
    d = new Date();
  }
  return MONTH_NAMES[d.getMonth() + 1];
}
