import * as React from 'react';
import { connect } from 'react-redux';
import API from '../../services/api';
import StateTree from '../../stores/tree';
import { Spinner } from '../ui/ui';
import {PrivacyPage} from "../../donor/components/privacy-terms/privacy-page";
import {TermsPage} from "../../donor/components/privacy-terms/terms-page";

interface PropsFromState {
  api: API;
}

class DocumentPage extends React.Component<
  { name: 'privacy' | 'terms' | 'challenge-terms' } & PropsFromState,
  { html: string }
> {
  state = {
    html: '',
  };

  /*async componentDidMount() {
    await this.fetchDocument();
  }*/

  /*private async fetchDocument() {
    const { api, name } = this.props;
    this.setState({
      html: await api.fetchDocument(name),
    });
  }*/

  render() {
    const { api, name } = this.props;
    if(name === "privacy") {
      return <PrivacyPage />;
    } else if (name === "terms" || name === "challenge-terms") {
      return <TermsPage />;
    }
    return <div>Не знайшлі дакумент. Калі ў вас ёсць пытанні - дашліце ліст да say@say.by</div>;
    /*const { html } = this.state;
    return html ? (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    ) : (
      <Spinner />
    );*/
  }
}

const mapStateToProps = ({ api }: StateTree) => ({
  api,
});

export default connect<PropsFromState>(mapStateToProps)(DocumentPage);
