import { Localized } from '@fluent/react';
import * as React from 'react';
import { trackNav, getTrackClass } from '../../services/tracker';
import URLS from '../../urls';
import {
  ContributableLocaleLock,
  LocaleNavLink,
  useLocale,
} from '../locale-helpers';

import './nav.css';

const LocalizedNavLink = ({ id, to }: { id: string; to: string }) => {
  const [locale] = useLocale();
  return (
    <Localized id={id}>
      <LocaleNavLink
        className={getTrackClass('fs', id)}
        to={to}
        exact
        onClick={() => trackNav(id, locale)}
      />
    </Localized>
  );
};

export default ({ children, ...props }: { [key: string]: any }) => {
  const locale = props.user?.account?.locales[0];
  const districtId =
    locale && `${locale.locale}${locale.accent ? ':' + locale.accent : ''}`;
  return (
    <nav {...props} className="nav-list">
      <div className="nav-links">
        <ContributableLocaleLock>
          <LocalizedNavLink id="contribute" to={URLS.SPEAK} />
        </ContributableLocaleLock>
        <LocalizedNavLink id="about" to={URLS.ABOUT} />
        <LocalizedNavLink
          id="my_district"
          to={districtId ? `/district-${districtId}` : URLS.MY_DISTRICT}
        />
        {/*{
        useAccount() && useAccount().username ? (
            <a href={"/" + (useAccount().donor_id + "").padStart(7, "0")}>Мой сертыфікат донара</a>
          ) :
          (
            <a href={"/0000000"}>Мой сертыфікат донара</a>
          )
      }*/}
      </div>
      {children}
    </nav>
  );
};
