import API from '../../services/api';
import {Sentence, Clip, FeatureType} from 'common';
import { Locale } from '../../stores/locale';
import { User } from '../../stores/user';
import { USER_KEY } from '../../stores/root';

interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  isJSON?: boolean;
  headers?: {
    [headerName: string]: string;
  };
  body?: any;
}

interface Vote extends Event {
  hasEarnedSessionToast?: boolean;
  showFirstContributionToast?: boolean;
  showFirstStreakToast?: boolean;
  challengeEnded?: boolean;
}

export function convertIdToDonorNumber(donorId: number) {
  return donorId.toString().padStart(7, '0');
}

//there's a copy of it on server/ ... /donor-db.ts
export interface PlayPoemInfo {
  title: string|null;
  author: string|null;
  source: string|null;
  districtName: string|null;
  listOfUrls: string[];
  listOfTexts: string[];
  listOfMedia?: string[];
}

interface WinnerDetails {
  donorId:number;
  isWinner:boolean;
  clientCheck:string;
}

//there's a copy of it on server/ ... /donor-db.ts
export interface DistrictPoem {
  poemName: string;
  poemAuthor: string;
  amountOfDonors: number;
  source: string;
  voicesUrl: string;
  recordedSentencesAmount: number;
  availableSentencesAmount: number;
  termId: number;
}

export interface DistrictPhoto {
  id: number;
  type: string;
  title: null;
  subtype: string;
  url: string;
  thumbnail_url: string;
  locale_id: number;
  accent: string;
  client_id: string;
  created_at: string;
  updated_at: string;
  districtId?: string;
}

export interface DistrictPhotos {
  meta: {
    total: number;
  };
  data: DistrictPhoto[];
}

//there's a copy of it on server/ ... /donor-db.ts
export interface DonorPublicProfile {
  username: string;
  avatarUrl: string;
  commonVoiceRecords: number;
  commonVoiceVotes: number;
  movaProRecords: number;
  movaProVotes: number;
  overallClips?: number;
  overallVotes?: number;
  latestMarathonRecords?: number;
  latestMarathonVotes?: number;
  donorId: number;
  isHonored: boolean;
  instagram?: string;
  linkedin?: string;
  facebook?: string;
  asto?: string;
  about?: string;
  country?: string;
  city?: string;
  suburb?: string;
  donorsAmountNearby?: number;
  volunteering?: string[];
  gender?: string;
  mainRegion?: { region: string; subregion: string } | null;
  exampleClipData?: { path: string; bucket: string };
  voice?: string;
  pro?: boolean;
  movaPro?: string[];
  visible: number;
}

//there's a copy of it on server/ ... /donor-db.ts
export interface DonorTopData {
  username: string;
  donorId: number;
  avatarUrl: string;
  total?: number;
  overallTotal?: number;
  overallClips?: number;
  overallVotes?: number;
  gender: number;
  pro?: boolean;
  hash?: string;
  certificateName?: string;
  completedAt?: string;
  position?: string;
}

export interface SuperiorDonorTopData {
  donorId: number;
  visible: boolean;
  total?: number;
  gender: number;
  position?: string;
  pro?:boolean;
}


//there's a copy of it on server/ ... /donor-db.ts
export interface DistrictMainInfo {
  name: string;
  districtId: string;
  voicesAmountLatestMarathon: number;
  votesAmountLatestMarathon: number;
  voicesAmountTotal: number;
  votesAmountTotal: number;
  score: number;
  voicesUrl: string;
  ratingPosition: number;
  donorsAmount: number;
  hasBooks?:boolean;
  proCertificatesStats?:ProCertificatesStats;
  geo?:PolygonCoordinates[];
}

export interface PolygonCoordinates {
  "lat":number;
  "lng":number;
}
//there's a copy of it on server/ ... /donor-db.ts
interface ProCertificatesStats {
  //string is a certificate name, number is amount of those certificates
  [key: string]: number;
}

//there's a copy of it on server/ ... /donor-db.ts
// export interface PlayPoemInfo {
//   url: string;
// }

//there's a copy of it on server/ ... /donor-db.ts
export interface Prize {
  id: number;
  name: string;
  course_tag: string;
}

const getChallenge = (user: User.State): string => {
  return user?.account?.enrollment?.challenge
    ? user.account.enrollment.challenge
    : null;
};

const CV_API_PROXY_PATH = location.origin + '/cv-orig/api/v1';
const API_PATH = location.origin + '/api/v1';

export default class DonorAPI {
  private readonly locale: Locale.State;
  private readonly user: User.State;
  api: API;

  constructor(api: API, locale: Locale.State, user: User.State) {
    this.locale = locale;
    this.user = user;
    this.api = api;
  }

  getLocaleCVProxyPath() {
    return this.locale
      ? CV_API_PROXY_PATH + '/' + this.locale
      : CV_API_PROXY_PATH;
  }

  getCVProxyClipPath() {
    return this.getLocaleCVProxyPath() + '/clips';
  }

  getDonarApiPath() {
    return this.api.getLocalePath() + '/donor';
  }
  isSuperiorUser(): boolean {
    try {
      if (this.user && this.user.userClients.length) {
        for (let x = 0; x < this.user.userClients.length; x++) {
          if (this.user.userClients[x].is_superior) {
            return true;
          }
        }
      }
    } catch(err) { }
    return false;
  }
  async fetchRandomSentences(count: number = 1): Promise<Sentence[]> {
    const isSuperior = this.isSuperiorUser();
    const amountOfDonarBySentences = isSuperior ? 10 : 5;
    const donarBySentences = await this.fetch(
      `${this.api.getLocalePath()}/sentences?count=${amountOfDonarBySentences}` + (isSuperior ? "&is_tts=true" : "")
    );
    let cvSentences:any = [];

    if(!isSuperior || !donarBySentences.length) {
      const amountOfCVSentences = count - donarBySentences.length;

      try {
        cvSentences = await this.fetch(
          `${this.getLocaleCVProxyPath()}/sentences?count=${amountOfCVSentences}&is_tts=true`
        );
      } catch (err) {
        //when CV fails - return only donor.by sentences
        return donarBySentences;
      }
    }

    let combinedSentences = [];

    //Now will do our first 5 and then 5 from CV
    for (let x = 0; x < donarBySentences.length; x++) {
      combinedSentences.push(donarBySentences[x]);
    }
    for (let x = 0; x < cvSentences.length; x++) {
      combinedSentences.push(cvSentences[x]);
    }
    return combinedSentences;

    //It was old way
    //mixing sentences together (the very first one is from donar.by)
    /* for (let x=0; x < donarBySentences.length; x++) {
      //insert them at every second one
      cvSentences.splice(x*2, 0 , donarBySentences[x]);
    }
    console.log(cvSentences);
    return cvSentences;*/
  }

  async fetchRandomClips(count: number = 1): Promise<Clip[]> {
    const isSuperior = this.isSuperiorUser();
    const amountOfDonarByClips =
      count === 1 ? this.randomInt(0, 1) : Math.floor(count / 2);
    const donarByClips = amountOfDonarByClips
      ? await this.fetch(
          `${this.api.getClipPath()}?count=${amountOfDonarByClips}`+ (isSuperior ? "&is_tts=true" : "")
        )
      : [];
    let cvClips:any =  [];
    if(!isSuperior || !donarByClips.length) {
      const amountOfCVClips = count - donarByClips.length;
      try {
        cvClips = await this.fetch(
          `${this.getCVProxyClipPath()}?count=${amountOfCVClips}`
        );
      } catch (err) {
        //When common voice fails - check only donor by clips
        return donarByClips;
      }
    }
    if(!cvClips) {
      return donarByClips;
    }
    //mixing sentences together (the very first one is from donar.by)
    for (let x = 0; x < donarByClips.length; x++) {
      //insert them at every second one
      cvClips.splice(x * 2, 0, donarByClips[x]);
    }
    return cvClips;
  }

  /**
   * Random integer in interval
   *
   * @param min
   * @param max
   * @returns {number}
   */
  randomInt(min: number, max: number): number {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async fetch(path: string, options: FetchOptions = {}): Promise<any> {
    const { method, headers, body, isJSON } = Object.assign(
      {
        isJSON: true,
      },
      options
    );

    const finalHeaders = Object.assign(
      isJSON
        ? {
            'Content-Type': 'application/json; charset=utf-8',
          }
        : {},
      headers
    );
    const { user } = this;

    if (path.startsWith(location.origin) && !user.account && user.userId) {
      finalHeaders['Authorization'] =
        'Basic ' + btoa(user.userId + ':' + user.authToken);
    } else if (
      path.includes(CV_API_PROXY_PATH) &&
      user.userClients &&
      user.userClients[1] &&
      user.userClients[1].client_id
    ) {
      //when we are authorized - but we send a request to CV proxy - than we still need to send authentication details
      //because on CV we are not actually authorized
      //   console.log(user.userClients[0].client_id);
      //   console.log(path);
      //user.userClients[0] - is used to login through auth0
      finalHeaders['Authorization'] =
        'Basic ' +
        btoa(
          user.userClients[1].client_id + ':' + user.userClients[1].auth_token
        );
      // finalHeaders['Cookie'] = "";
    }

    const response = await fetch(path, {
      method: method || 'GET',
      headers: finalHeaders,
      credentials: 'same-origin',
      body: body
        ? body instanceof Blob
          ? body
          : JSON.stringify(body)
        : undefined,
    });
    if (response.status == 401) {
      localStorage.removeItem(USER_KEY);
      location.reload();
      return;
    }
    if (response.status >= 400) {
      if (response.statusText.includes('save_clip_error')) {
        throw new Error(response.statusText);
      }
      throw response;
    }
    return isJSON ? response.json() : response.text();
  }
  uploadClipCVProxy(
    blob: Blob,
    sentenceId: string,
    fromDemo?: boolean
  ): Promise<{
    showFirstContributionToast?: boolean;
    hasEarnedSessionToast?: boolean;
    showFirstStreakToast?: boolean;
    challengeEnded: boolean;
  }> {
    // make sure nginx server has allow_underscore turned on
    return this.fetch(this.getCVProxyClipPath(), {
      method: 'POST',
      headers: {
        'Content-Type': blob.type,
        sentence_id: sentenceId,
        challenge: getChallenge(this.user),
        from_demo: fromDemo ? 'true' : 'false',
        source: 'web',
      },
      body: blob,
    });
  }

  /**
   * Vote for common voice sentence.
   *
   * The scheme is the following:
   * 1. We vote into CV proxy
   * 2. then get or create a blank empty clip to represent the vote on donar by
   * 3. then we send a vote to that clip
   *
   * @param {string} id
   * @param {boolean} isValid
   * @returns {Promise<Vote>}
   */
  async saveVoteCV(id: string, isValid: boolean): Promise<Vote> {
    //first send to CV through proxy
    const cvResult = await this.fetch(
      `${this.getCVProxyClipPath()}/${id}/votes`,
      {
        method: 'POST',
        body: {
          isValid,
          challenge: null,
        },
      }
    );
    let clipCreatorClientId = null;
    let sentenceId = null;
    if (cvResult && cvResult.glob) {
      [clipCreatorClientId, sentenceId] = cvResult.glob.split('/');
    }
    //TAKE GLOB FROM CV response, check client_id - if does not exist, create it, then create a clip for this sentence and then call saveVote as usual
    //and now save a fake vote to donar.by
    const donorClipInfo = await this.fetch(
      `${this.getDonarApiPath()}/${id}/get-create-blank-cv-clip`,
      {
        method: 'POST',
        body: {
          isValid,
          challenge: getChallenge(this.user),
          clip_creator_client_id: clipCreatorClientId,
          sentence_id: sentenceId,
        },
      }
    );
    //now vote for it on donar.by
    return await this.api.saveVote(donorClipInfo.clip_id, isValid);
  }

  fetchCVClipsStats(locale?: string): Promise<
    {
      date: string;
      total: number;
      valid: number;
    }[]
  > {
    return this.fetch(
      CV_API_PROXY_PATH + (locale ? '/' + locale : '') + '/clips/stats'
    );
  }

  fetchPriorityStats(): Promise<
    {
      source: string;
      name: string;
      title: string;
      linkName: string;
      clipsRequired: number;
      clipsRecordered: number;
      percentageRecorded: number;
      percentageValidated?: number;
    }[]
  > {
    return this.fetch(this.api.getLocalePath() + '/donor/priority-stats');
  }
  fetchPublicProfile(profileId: number): Promise<DonorPublicProfile> {
    return this.fetch(this.api.getLocalePath() + `/donor/${profileId}/public`);
  }

  transferCommonvoice(type: 'default' | 'file' | 'gravatar', file?: Blob) {
    return this.fetch(
      this.api.getLocalePath() + '/donor/transfer-commonvoice/' + type,
      {
        method: 'POST',
        isJSON: false,
        ...(file
          ? {
              body: file,
            }
          : {}),
      }
    ).then(body => JSON.parse(body));
  }

  fetchHonoredList(): Promise<DonorTopData[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/honored-list`);
  }
  fetchTopSpeak(): Promise<DonorTopData[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/top-speak`);
  }
  fetchTopSuperiorSpeak(): Promise<SuperiorDonorTopData[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/top-superior-speak`);
  }
  fetchTopListen(): Promise<DonorTopData[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/top-listen`);
  }
  fetchDistrictsRating(isForThisWeek: boolean): Promise<DistrictMainInfo[]> {
    return this.fetch(
      this.api.getLocalePath() +
        `/donor/districts-rating?isForThisWeek=${isForThisWeek ? 'true' : ''}`
    );
  }
  fetchDistrictRating(districtId: string): Promise<DistrictMainInfo> {
    return this.fetch(
      this.api.getLocalePath() + `/donor/${districtId}/district-main-info`
    );
  }

  fetchDistrictPoems(districtId: string): Promise<DistrictPoem[]> {
    return this.fetch(
      this.api.getLocalePath() + `/donor/${districtId}/district-poems`
    );
  }
  fetchDistrictDonors(districtId: string): Promise<DonorPublicProfile[]> {
    return this.fetch(
      this.api.getLocalePath() + `/donor/${districtId}/district-donors`
    );
  }
  fetchTeam(): Promise<DonorTopData[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/team`);
  }
  fetchAvailablePrizes(courseRunId: string): Promise<Prize[]> {
    return this.fetch(
      this.api.getLocalePath() + `/donor/prize?courseRunId=${courseRunId}`
    );
  }
  fetchKupalaSeconds(): Promise<number> {
    return this.fetch(this.api.getLocalePath() + `/donor/kupala-seconds`);
  }
  fetchKolasSeconds(): Promise<number> {
    return this.fetch(this.api.getLocalePath() + `/donor/kolas-seconds`);
  }
  isWinner(): Promise<WinnerDetails> {
    return this.fetch(this.api.getLocalePath() + `/donor/is-winner`);
  }
  // example of usage this.donorAPI.saveMedia(file, { type: 'diary', locale_id: 1 });
  // where file is a Blob like files.item(0) from async saveFileAvatar(files: FileList)
  // pass params for media via hearders. Did not find a way to handle it via body with file at the same time

  saveMedia(
    file: Blob,
    params: {
      districtId: string;
      type?: string;
      subtype?: string;
      title?: string;
    }
  ): Promise<DistrictPhotos[]> {
    let headers: any = {
      'Content-Type': file.type,
      type: params.type || 'diary',
      district_id: params.districtId,
    };
    if (params.title) {
      headers.title = params.title;
    }
    if (params.subtype) {
      headers.subtype = params.subtype;
    }

    return this.fetch(this.api.getLocalePath() + '/donor/media', {
      method: 'POST',
      headers: headers,
      body: file,
    });
  }

  fetchDistrictMedia(districtId: string, params: any): Promise<DistrictPhotos> {
    let queryPart = '';
    for (const property in params) {
      queryPart += `${property}=${params[property]}&`;
    }
    return this.fetch(
      this.api.getLocalePath() + `/donor/${districtId}/media?${queryPart}`
    );
  }

  fetchMedia(params: any): Promise<DistrictPhotos> {
    let queryPart = '';
    for (const property in params) {
      queryPart += `${property}=${params[property]}&`;
    }
    return this.fetch(this.api.getLocalePath() + `/donor/media?${queryPart}`);
  }

  deleteMedia(id: number): any {
    return this.fetch(this.api.getLocalePath() + `/donor/media/${id}`, {
      method: 'DELETE',
    });
  }

  updateMedia(id: number, params: any): any {
    return this.fetch(this.api.getLocalePath() + `/donor/media/${id}`, {
      method: 'PATCH',
      body: params,
    });
  }

  recogniseSpeech(blob: Blob, sentenceId: string): any {
    let headers: any = {
      'Content-Type': blob.type,
      sentence_id: sentenceId,
    };

    return this.fetch(this.api.getLocalePath() + '/donor/recognise-speech', {
      method: 'POST',
      headers: headers,
      body: blob,
    });
  }
  fetchDistrictGeo(districtId:string): Promise<PolygonCoordinates[]> {
    return this.fetch(this.api.getLocalePath() + `/donor/${districtId}/geo`);
  }
  saveDistrictGeo(districtId:string, polygon:PolygonCoordinates[]): Promise<PolygonCoordinates[]>{
    return this.fetch(
      this.api.getLocalePath() + `/donor/${districtId}/geo-set`,
      {
        method: 'POST',
        body: {
          district_id: districtId,
          polygon: polygon
        }
      }
    );
  }
}
